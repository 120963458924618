import { CrownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Input, List, Modal, Pagination, Row, Skeleton, Table, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from 'antd/es/table';

import './CompanySelectorComponent.scss';
import {AddCompany, CompanyFilter, SmallCompanyData, SmallCompanyDataList} from "../../../models/SmallCompanyData";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { AgencyAndCompanyAddForm, AgencyAndCompanyRequestSignUpForm } from "../..";
import { GetInversedWarnIcon, GetNoResultIncon } from "../../../utils/icons";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import ApiResponse from "../../../models/api/ApiResponse";
import { GetPaginationLocale } from "../../../utils/locales";
import { AddAgency } from "../../../models/SmallAgencyData";

interface CompanySelectorComponentProps{
    getCompaniesData: (filter: CompanyFilter) => Promise<SmallCompanyDataList> ,
    onNextStep: (step?: number, extra?: string) => void,
    onPreviousStep: (step?: number, extra?: string) => void,
    onSelectCompany: (companyData: SmallCompanyData) => void,
    onSignUpRequest: (type: string, selectedId: string, email: string, agentName: string) => Promise<ApiResponse | null>
}

const CompanySelectorComponent = (props: CompanySelectorComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const appConfig = useAppConfig();

    const defaultFilter = {
        address: "",
        commercialName: "",
        fiscalId: "",
        phone: "",
        numRecords: 6,
        page: 1
    };

    const [showCompanyRequestForm, setShowCompanyRequestForm] = useState<boolean>(false);

    const { onNextStep, onPreviousStep, onSelectCompany, getCompaniesData, onSignUpRequest } = props;
    
    const [companiesData, setCompaniesData] = useState<SmallCompanyDataList>({elements:[],totalRecords:0});

    const [isLoadingData, setIsLoading] = useState<boolean>(false);
    const [isNextButtonActive, setIsNextButtonActive] = useState<boolean>(false);

    const [form] = useForm();

    const [selectedNumRecords, setSelectedNumRecords] = useState<number>(6);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    const [showRequestRegistration, setShowRequestRegistration] = useState<boolean>(false);
    const getCompanies = async (filterData : CompanyFilter) => {
        setIsLoading(true);
        var data = await getCompaniesData(filterData);
        setShowRequestRegistration(data.totalRecords <= 6 && data.totalRecords > 0);
        setIsLoading(false);
        setCompaniesData(data);
    }

    const getFilteredData = () : void => {
        var filterData : CompanyFilter = {
            address: form.getFieldValue("address") ?? "",
            commercialName: form.getFieldValue("commercialName") ?? "",
            fiscalId: form.getFieldValue("fiscalId") ?? "",
            phone: form.getFieldValue("phone") ?? "",
            numRecords: selectedNumRecords,
            page: selectedPage
        }

        getCompanies(filterData);
    }

    const onFormChange = () : void => getFilteredData();

    const onChangeCompany = (x: any) : void => {
        if (x?.target?.value?.length > 0){
            const companyData : SmallCompanyData = JSON.parse(x.target.value);

            if (companyData.isAlreadyUsed){
                setIsNextButtonActive(false);
                showIsUsedForm(companyData)
            }else{
                onSelectCompany(companyData);
                setIsNextButtonActive(true);
            }
        }
    }

    const closeIsUsedModal = () : void =>{
        setSelectedRowKeys([]);
        setShowIsUsed(false);
    }
    
    const closeAddRecordModal = () : void => setShowCompanyRequestForm(false);

    useEffect(()=>{
        getFilteredData();
    },[selectedPage, selectedNumRecords])

    useEffect(()=>{
        getCompanies(defaultFilter);
    },[])

    // rowSelection object indicates the need for row selection
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: SmallCompanyData[]) => {
            setSelectedRowKeys(selectedRowKeys);
            if (selectedRows[0].isAlreadyUsed){
                setIsNextButtonActive(false);
                showIsUsedForm(selectedRows[0])
            }else{
                onSelectCompany(selectedRows[0]);
                setIsNextButtonActive(true);
            }
        }
    };

    const [showIsUsed, setShowIsUsed] = useState<boolean>(false);
    const [selectedCompanyData, setSelectedCompanyData] = useState<SmallCompanyData>();
    const showIsUsedForm = (companyData: SmallCompanyData) : void => {
        setSelectedCompanyData(companyData);
        setShowIsUsed(true);
    }

    const tableColumns : ColumnsType<SmallCompanyData> = [
        {
            title: t('components.company-selector.filter.commercial-name'),
            dataIndex: "name",
            key: "commercialName",
            render: (item: string, record: SmallCompanyData) => {
                return <div className="company-name">
                            {item}
                       </div>
            }
        },
        {
            title: t('components.company-selector.filter.address'),
            dataIndex: "address",
            key: "address",
            render: (item: string) => item
        },
        {
            title: t('components.company-selector.filter.phone'),
            dataIndex: "telephone",
            key: "telephone",
            render: (item: string) => item
        },
        {
            title: t('components.company-selector.filter.fiscal-id'),
            dataIndex: "identityCode",
            key: "identityCode",
            render: (item: string, record: SmallCompanyData) => {
                return <div className="company-name">
                            
                                {
                                    record.isAlreadyUsed ? 
                                        <div className="triangle">
                                                <Tooltip title={t(`components.company-selector.this-company-is-used`)}>
                                                    <CrownOutlined  style={{color: "white", fontSize: "13px"}} className="question"/>
                                                </Tooltip>
                                        </div> 
                                    : 
                                        <></>
                                }
                            {item}
                       </div>
            }
        }
    ];

    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const signUpRequest = (email: string, name: string) : void => {
        if (selectedCompanyData){
            setIsRequesting(true);
            onSignUpRequest("company", selectedCompanyData?.id, email, name).then((r: ApiResponse | null) => {
                if (r?.typeText === "success"){
                    setShowIsUsed(false);
                    onNextStep(3, 'waiting-request');
                }
            })
            .finally(()=>{
                setIsRequesting(false);
            })
        }
        
    }

    const onCreatedRecord = (organizationData: AddAgency | AddCompany) : void => {
        closeAddRecordModal();
        form.setFieldValue("commercialName", organizationData.commercialName);
        form.setFieldValue("fiscalId", organizationData.fiscalId);
        getFilteredData();
    }

    return(
        <div className="company-selector-component">
            <Modal className="app-modal no-header-boreder" title={
                                                <Row>
                                                    <Col xs={4} sm={2}>
                                                        {GetInversedWarnIcon('app-icon')}
                                                    </Col>
                                                    <Col xs={20} sm={22}>
                                                        {
                                                            HTMLReactParser(t("components.company-selector.modal-request-title", [selectedCompanyData?.name]))
                                                        }
                                                    </Col>
                                                </Row>
                                            } open={showIsUsed} footer={null} onCancel={closeIsUsedModal}>
                <AgencyAndCompanyRequestSignUpForm key="modal-form-request-sign-up" name={selectedCompanyData?.name!} resetForm={showIsUsed} onSignUpRequest={signUpRequest} isRequesting={isRequesting}/>
            </Modal>
            
            <Modal className="app-modal" title={t("components.company-selector.modal-title")} open={showCompanyRequestForm} footer={null} onCancel={closeAddRecordModal}>
                {t("components.company-selector.modal-subtitle")}
                <AgencyAndCompanyAddForm key="modal-form-add-record" recordType="company" onCreatedRecord={onCreatedRecord} onCloseModal={closeAddRecordModal}/>
            </Modal>

            <div className="filter">
                <Form form={form} layout="vertical" >
                    <Row gutter={16}>
                    <Col xs={24} md={12}>
                            <Form.Item
                                label={t("components.company-selector.filter.commercial-name")}
                                name="commercialName"
                            >
                                <Input onChange={onFormChange} allowClear className="app-input"  prefix={<SearchOutlined className="prefix-search-icon" />}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("components.company-selector.filter.address")}
                                name="address"
                            >
                                <Input onChange={onFormChange} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon" />}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Collapse className="app-collapse advanced-search-container" items={[
                                {
                                    key: '1',
                                    label: t('components.company-selector.filter.advanced-search'),
                                    headerClass: "advanced-search-header right-align",
                                    children: <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t("components.company-selector.filter.phone")}
                                                name="phone"
                                            >
                                                <Input onChange={onFormChange} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon" />}/>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t("components.company-selector.filter.fiscal-id")}
                                                name="fiscalId"
                                            >
                                                <Input onChange={onFormChange} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon" />}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>,
                                }
                            ]}/>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
                companiesData.totalRecords == 0 ? 
                    <div className="no-records-found">
                        {GetNoResultIncon("app-icon")}
                        {t("components.company-selector.not-record-found")}
                        <div className="request-company-registration" onClick={()=>{setShowCompanyRequestForm(true)}}>
                            {HTMLReactParser(t('components.company-selector.modify-or-add-record'))}
                        </div>
                    </div>
                :
                    <div className="company-list">
                        <Skeleton active loading={isLoadingData}>
                                
                                {
                                    appConfig.device !== "mobile"
                                    ?
                                        <Table className="app-table" columns={tableColumns} dataSource={companiesData.elements} rowSelection={{ type: 'radio', ...rowSelection,}} pagination={false} rowKey={"id"} />
                                    :
                                        <div className="app-list-container">
                                            <div className="headers">
                                                <Row gutter={0}>
                                                    <Col xs={24} >
                                                        <Row>
                                                            <Col xs={2} className="mobile-column"></Col>
                                                            <Col xs={12} className="mobile-column">Nombre comercial</Col>
                                                            <Col xs={10} className="mobile-column">Id. Fiscal</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                            <List
                                                className={`app-list`}
                                                itemLayout="vertical"
                                                dataSource={companiesData.elements}
                                                renderItem={(item: SmallCompanyData) =>
                                                    <List.Item >
                                                        <Row gutter={0}>
                                                            <Col xs={3} className="list-item flex-center" style={{minHeight: "60px"}}>
                                                                <input onChange={onChangeCompany} type="radio" id={item.identityCode!} name="selectedCompany" value={JSON.stringify(item)} className="ant-radio-input"></input>
                                                            </Col>
                                                            <Col xs={11} className="list-item flex-center" style={{minHeight: "60px"}}>
                                                                <span className="company-name">{item.name.toUpperCase()}</span>
                                                            </Col>
                                                            <Col xs={10} className="list-item flex-center fiscal-id" style={{minHeight: "60px"}}>
                                                                {
                                                                    item.isAlreadyUsed 
                                                                    ? 
                                                                        <div className="triangle">
                                                                            <Tooltip title={t(`components.company-selector.this-company-is-used`)}>
                                                                                <CrownOutlined  style={{color: "white", fontSize: "13px"}} className={`question ${appConfig.device}`}/>
                                                                            </Tooltip>
                                                                        </div> 
                                                                    : 
                                                                        <></>
                                                                }
                                                                {item.identityCode}
                                                            </Col>
                                                            
                                                        </Row>
                                                        <Row className="list-additional-info">
                                                            <Col xs={24}  className="list-item">
                                                                <strong>{t('components.company-selector.address')}: </strong>
                                                                <span>{item.address}</span>
                                                            </Col>
                                                            <Col xs={24}  className="list-item">
                                                                <strong>{t('components.company-selector.phone')}: </strong>
                                                                <span>{item.telephone}</span>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </List.Item>
                                                }
                                            />
                                        </div>
                                }
                                
                                <div className="total-records">
                                    <div className="pagination">
                                        <Pagination
                                            locale={GetPaginationLocale(t)}
                                            total={companiesData.totalRecords}
                                            pageSizeOptions={["6", "20", "50", "100"]}
                                            defaultPageSize={6}
                                            defaultCurrent={1}
                                            onChange={(page, numRecords)=>{
                                                setSelectedPage(page);
                                                setSelectedNumRecords(numRecords);
                                            }}
                                        />
                                    </div>
                                    <div className="counter">
                                        {t('components.company-selector.total-records',[companiesData.totalRecords])}
                                    </div>
                                </div>
                        </Skeleton>
                    </div>
            }
            
            {
                showRequestRegistration 
                ? 
                    <div className="request-company-registration" onClick={()=>{setShowCompanyRequestForm(true)}}>
                        {HTMLReactParser(t('components.company-selector.new-record-request'))}
                    </div>
                : 
                    <>
                    </>
            }
            <div className="actions">
                <Button onClick={()=>{onPreviousStep();}} className="app-white-button">
                    {t('components.company-selector.actions.prev')}
                </Button>

                <Button onClick={()=>{onNextStep();}} className="app-button" disabled={!isNextButtonActive}>
                    {t('components.company-selector.actions.next')}
                </Button>
            </div>
        </div>
    )
}


export default CompanySelectorComponent;