// Interfaces TypeScript

import { AlternativeDay, Discount, Hotel, Price } from "./Commons";

interface LocationAvailabilityResponse {
  hotels: Hotel[];
  locationAvailabilities: LocationAvailability[];
}

interface LocationAvailability {
  hotelCode: string;
  minimumPrice: Price;
  discount: Discount | null;
  availabilityType: AvailabilityType;
  restrictions: Restrictions[];
  alternativeDays: AlternativeDay[];
}


export enum AvailabilityType {
  HasAvailability = 0,
  NoAvailabilityHotelClosed = 1,
  NoAvailabilityNoquota = 2,
  NoAvailabilityExistingRestrictions = 3,
}

export enum Restrictions {
  MinLos = 0,
  MaxLos = 1,
  Release = 2,
  Unknow = 3,
}

export type{
  LocationAvailabilityResponse,
  LocationAvailability,
}


