// Interfaces TypeScript

import { AmountType, Discount, Price, Image, AlternativeDay, Hotel } from "./Commons";

interface HotelAvailabilityResponse {
  hotels: Hotel[];
  hotelAvailabilities: HotelAvailability[];
  crossSellingProducts: CrossSellingProduct[];
}

interface HotelAvailability {
  hotelCode: string;
  roomAvailabilities: RoomAvailability[];
  ErrorCode: string;
}

interface RoomAvailability {
  roomCode: string;
  quota: number;
  rates: Rate[];
  roomIndex: string;
}


interface Rate {
  code: string;
  name: string;
  isOffer: boolean;
  isNoRefundable: boolean;
  meals: Meal[];
  alternativeDays: AlternativeDay[];
  additionalInfo: additionalInfo[];
  modificationCost?: number;
}

interface CancellationPolicy {
  Code: string;
  penalty: penalty;
  DeadLine: string | null;
  HasExpired: boolean;
  NonRefundable: boolean;
}

interface penalty {
  type: PenaltyType;
  penaltyTypeText: string;
  amount: number;
}



interface Meal {
  code: string;
  IsOffer: boolean;
  PaymentType: PaymentType;
  PaymentTypeText: string;
  price: price;
  rateKey: string;
  Discount?: Discount;
  cancellationPolicies: CancellationPolicy[];
  priceBreakDown: PriceBreakDown[];
  totalPriceModifier: PriceModifier;
}

interface PriceBreakDown{
  priceDate: Date;
  totalPrice: Price;
  RoomPrice: Price;
  mealPrice: Price;
  guaranteedQuota: boolean;
  priceModifier: PriceModifier;
}

interface PriceModifier{
  code: string;
  currencyIsoCode: string;
  amount: number;
  amountType: 'fixed' | 'percent';
  isAlreadyApplied: boolean;
}



interface price {
  netPrice: Price;
  pvpPrice: Price;
  commission?: Price;
  priceType: number;
  taxIncluded: boolean;

  prePayments: any[];

  //TODO: Borrar la siguiente línea, es solo para que no fallen los mocks
  Commissions: any;
}




interface Commission {
  PaymentType: PaymentType;
  CommissionWithGuarantee: Price;
  CommissionWithoutGuarantee: Price;
  NetWithGuarantee: Price;
  NetWithoutGuarantee: Price;
}

interface MixedPayment {
  Amount: number;
  AmountType: AmountType;
}





interface additionalInfo {
  Message: string;
  AlertType: AlertType;
}



interface CrossSellingProduct {
  ProductCode: string;
  ServicePricingType: string;
  RoomCodes: string[];
  hotelCodes: string[];
  Name: string;
  ShortDescription: string;
  LargeDescription: string;
  ImageUrl: Image;
  IsOffer: boolean;
  Price: Price;
  Discount: Discount;
}

export enum PenaltyType {
  Percent,
  Fix,
  Nights,
}

export enum PaymentType {
  Hotel,
  PrePayCard,
  PrePayTransfer,
  PrePayPayPal,
  Credit,
}

export enum AlertType {
  Info,
  Warning,
  Danger,
}

export type{
  HotelAvailabilityResponse,
  HotelAvailability,
  RoomAvailability,
  Rate,
  CancellationPolicy,
  penalty,
  Meal,
  price,
  Commission,
  MixedPayment,
  additionalInfo,
  CrossSellingProduct,
}
