import { useTranslation } from 'react-i18next';
import './ProfilePage.scss'
import { EditProfile } from '../../../components';
import { useState } from 'react';
import UpdatePersonalData from '../../../models/UpdatePersonalData';
import {  updateProfilePersonalData } from '../../../api/repositoryEP';
import { useSessionData } from '../../../Contexts/SessionDataContext';


const ProfilePage = () : JSX.Element => {
    const { t } = useTranslation();
    const sessionData = useSessionData();

    const onChange = (key: string) => {
        console.log(key);
      };

    const [savingIndex, setSavingIndex] = useState<number>(0);


    const onSaveProfile  = (data: UpdatePersonalData) : void => {
      setSavingIndex(1);
      data.id = sessionData.userData.id;
      updateProfilePersonalData(data).then((res) => {     
        if(res?.type == 1){
          var sesData = sessionData;  
          sesData.userData.name = data.name ?? '';
          sesData.userData.surname = data.surname ?? '';
          sesData.userData.secondSurname = data.secondSurname ?? '';    
          window.sessionStorage.setItem("user-data", JSON.stringify(sesData));  
        } 
       
      }).finally(() => {  setSavingIndex(0);   });

    }

    const onSavePersonalData = (data: UpdatePersonalData) : void => {
      setSavingIndex(2);
      data.id = sessionData.userData.id;
      updateProfilePersonalData(data).then((res) => {
        if(res?.type == 1){
          var sesData = sessionData; 
          sesData.userData.email = data.email ?? '';
          sesData.userData.phone = data.phone ?? '';
          window.sessionStorage.setItem("user-data", JSON.stringify(sesData));
        }

               
      }).finally(() => {  setSavingIndex(0);   });
    }

    const onChangePassword = (data: UpdatePersonalData) : void => {
      setSavingIndex(3);
      data.id = sessionData.userData.id;
      updateProfilePersonalData(data).then((res) => {
        if(res?.type == 1){
          var sesData = sessionData; 
          sesData.userData.username = data.userName ?? '';
          window.sessionStorage.setItem("user-data", JSON.stringify(sesData));
         
        }

               
      }).finally(() => {  setSavingIndex(0);   });
   
    }

    
    const onSaveRewardsId = (data: UpdatePersonalData) : void => {
      setSavingIndex(4);
      data.id = sessionData.userData.id;
      updateProfilePersonalData(data).then((res) => {     
        if(res?.type == 1){
          var sesData = sessionData;  
          sesData.userData.UAID = data.rewardsId ?? '';       
          window.sessionStorage.setItem("user-data", JSON.stringify(sesData));  
        } 
       
      }).finally(() => {  setSavingIndex(0);   });

    }


    const onSaveNotifications =  (data: UpdatePersonalData) : void => {
      setSavingIndex(5);
      data.id = sessionData.userData.id;
      updateProfilePersonalData(data).then(() => {     
      }).finally(() => {  setSavingIndex(0);   });

    }


    return (
        <div className="profile-page">
            <h2 className="title">{t('pages.profile-page.main-title')}</h2>
            <h3 className="sub-title">{t('pages.profile-page.subtitle')}</h3>

            <EditProfile 
                onSaveProfile={onSaveProfile} 
                onSaveContactData={onSavePersonalData}
                onSavePassword={onChangePassword}
                onSaveNotifications={onSaveNotifications}
                onSaveRewardsId={onSaveRewardsId}
                savingIndex={savingIndex}/>
        </div>
    )
}

export default ProfilePage;