import { registerModal, useModal } from '@gluedigital/modal'
import { HotelModal } from '@beds2b-group/reusable-components'

export default function HotelModalWrapper(hotel: any) {
  const modal = useModal()

  return (
    <HotelModal hotel={hotel} handleClose={() => modal.hide()} />
  )
}

registerModal('hotel-modal', HotelModalWrapper)