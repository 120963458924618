import {
  AdditionalInfoIcons,
  Meal,
  Policy,
  Room,
  RoomRate,
} from "@beds2b-group/reusable-components/dist/types";
import { Hotel, RoomStaticData, Image, AmountType, ModifierType } from "../models/availability/Commons";
import {
  additionalInfo,
  AlertType,
  CancellationPolicy,
  HotelAvailability,
  HotelAvailabilityResponse,
  PenaltyType,
  RoomAvailability,
} from "../models/availability/HotelAvailability";
import { RoomCard } from "@beds2b-group/reusable-components";

export function HotelsToAppAvailability(
  hotelAvailability: HotelAvailabilityResponse,
  actualStepRoomSelector: number,
  onSelectRoom: (roomData: any, mealData: any, rateData: any) => void,
  modal: any
): JSX.Element[] {
  let result: JSX.Element[] = [];

  let hotelCode: string = '';

  const availabilityData: RoomAvailability[] = [];


  hotelAvailability.hotelAvailabilities.forEach((hotelAvailability) => {
    hotelCode = hotelAvailability.hotelCode;

    hotelAvailability.roomAvailabilities.forEach((roomAvailability) => {
      if (Number.parseInt(roomAvailability.roomIndex) == actualStepRoomSelector) {
        availabilityData.push(roomAvailability);
      }
    });
  });
  
  availabilityData.forEach((roomAvailability: RoomAvailability, index: number) => {
    const staticHotelData: Hotel | undefined = hotelAvailability.hotels.find(
      (hotel) => {
        return hotel.code == hotelCode;
      }
    );

    const staticRoomData =
      staticHotelData?.hotelStaticData?.rooms.find(
        (room: RoomStaticData) => {
          return room.code == roomAvailability.roomCode;
        }
      );


    const staticRoomImages: string[] = staticRoomData?.images?.sort((i1, i2) => i1.order - i2.order).map((image: Image) => image.url ?? "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg") || ["https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"];
     
    let priceRoomFrom = Number.MAX_SAFE_INTEGER;
    let priceFrom = Number.MAX_SAFE_INTEGER;
    let currency = "";
    const rates: Array<RoomRate> = roomAvailability.rates.map((rateInfo) => {
      let meals: Array<Meal> = [];

      rateInfo.meals.sort((mealA, mealB) => {
        //Ordenamos por precio de menor a mayor
        return mealA.price.pvpPrice.amount <=
          mealB.price.pvpPrice.amount
          ? -1
          : 1;
      }).map((meal) => {

        let staticMealData =
          staticHotelData?.hotelStaticData?.meals.find(
            (staticMeal) => {
              return staticMeal.code == meal.code;
            }
          );

          let discountData : {
            amount: number;
            type: 'percent' | 'fixed';
            alreadyApplied: boolean;
        } | undefined = undefined;

          if (rateInfo.isOffer && meal.totalPriceModifier){
            var discountInfo = meal.totalPriceModifier;

            if (discountInfo.amount < 0){
              discountInfo.amount *= -1;
            }

            discountData = {
              alreadyApplied: discountInfo.isAlreadyApplied,
              amount: discountInfo.amount ?? 0, 
              type: discountInfo.amountType 
            }
          }

          // PVP = 0
          // NET = 1
          var isPvp = meal.price.priceType == 0;

          priceFrom = isPvp ? meal.price.pvpPrice.amount : meal.price.netPrice.amount;
          currency = isPvp ? meal.price.pvpPrice.currencyIsoCode : meal.price.netPrice.currencyIsoCode;
          
          if (priceRoomFrom > priceFrom){
            priceRoomFrom = priceFrom;
          }


          meals.push({
            rateKey: meal.rateKey,
            code: meal.code,
            name: staticMealData?.name ?? "RateN/M", //Rate Not Mapped
            pvp: {
              currency: currency,
              amount: meal.price.pvpPrice.amount,
            },
            net: {
              currency: currency,
              amount: meal.price.netPrice.amount,
            },
            commission: {
              currency: currency,
              amount: meal.price.commission?.amount ?? 0,
            },
            isPvp: isPvp,
            discount: discountData
          });
      });
      
      rateInfo.additionalInfo = [];
      if (rateInfo.isNoRefundable){
        rateInfo.additionalInfo.push({
          AlertType: AlertType.Danger,
          Message: `Tarifa no reembolsable`,
        });
      }
      

      var additionalInfo = rateInfo.additionalInfo.map(
        (info: additionalInfo) => {
          let iconType: string = "ok" as AdditionalInfoIcons;
          switch (info.AlertType) {
            case AlertType.Warning:
              iconType = "attention-circled";
              break;
            case AlertType.Danger:
              iconType = "attention";
              break;
            case AlertType.Info:
            default:
              iconType = "ok";
              break;
          }

          return {
            icon: iconType as AdditionalInfoIcons,
            message: info.Message,
          };
        }
      );

      let cancellationPolicies = {
        policies: [] as {
          refundable: boolean;
          currency: string;
          deadLine: Date;
          penalty: number;
        }[],
        description: "",
      };

      rateInfo.meals[0].cancellationPolicies.forEach((policy: CancellationPolicy) => {
        let penaltyType: string = "";
        switch (policy.penalty.type) {
          case PenaltyType.Fix:
            penaltyType = "Fija";
            break;
          case PenaltyType.Percent:
            penaltyType = "Porcentaje";
            break;
          case PenaltyType.Nights:
            penaltyType = "Noches";
            break;
        }

        cancellationPolicies.policies.push({
          refundable: !policy.NonRefundable,
          currency: currency,
          deadLine: policy.DeadLine ? new Date(policy.DeadLine) : new Date(),
          penalty: policy.penalty.amount
        });
      });

      const rate: RoomRate = {
        meals: meals,
        name: rateInfo.name ?? "N/D (7) (Code)",
        dates: [new Date(), new Date()],
        additionalInfo: additionalInfo,
        isOffer: rateInfo.isOffer,
        cancelPolicies: cancellationPolicies,
      };

      return rate;
    });


    let roomData: Room = {
      code: roomAvailability.roomCode,
      beds: staticRoomData?.roomBeds?.length ?? 0,
      images: staticRoomImages ?? [],
      name: staticRoomData?.name ?? "RoomN/M", //Room name Not Mapped
      price: {
        currency: currency,
        amount: priceRoomFrom,
      },
      remainingRooms: roomAvailability.quota,
      //TODO: Mapear servicios
      services: [],
      description: staticRoomData?.largeDescription ?? "LD N/M",
      shortDescription: staticRoomData?.shortDescription ?? "SD N/M",
    };


    
    

    result.push(
      <RoomCard
        key={`${roomData.code}-${index}`}
        roomIndex={Number.parseInt(roomAvailability.roomIndex)}
        room={roomData}
        roomRates={rates}
        nights={0}
        modals={{
          showImages: (props)=>{
            modal.show('room-modal', roomData)
          },
          showRoomInfo: ()=> {
            modal.show('room-modal', roomData)
          },
          showRateInfo: (roomData: Omit<Room, 'beds'>, rateInfo: RoomRate, selectedMeal: Meal) => {
            console.log('RoomModalWrapper.tsx ->', roomData, rateInfo, selectedMeal)
            modal.show('room-rate-modal', {rateData: rateInfo, mealData: selectedMeal, roomName: roomData.name})
          },
        }}
        handleBooking={function (
          room: Omit<Room, "beds">,
          meal: Meal,
          rate: RoomRate
        ): Promise<void> {
          console.log("HotelsToAppAvailability.tsx -> handleBooking ->", room, meal, rate);
          onSelectRoom(room, meal, rate);
          return Promise.resolve();
        }}
      />
    );
  });

  return result;
}
