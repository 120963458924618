
import { Col, List, Pagination, Popconfirm, Row, Table, Tooltip } from 'antd'
import UsersInfo, { SmallUsersDataList } from '../../../models/UsersInfo'
import './UsersListComponent.scss'
import { ColumnsType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { GetPaginationLocale } from '../../../utils/locales'
import HTMLReactParser from 'html-react-parser'
import { useAppConfig } from '../../../Contexts/AppConfigContext'
import { useRef } from 'react';

interface UsersListComponentProps {
    userList: SmallUsersDataList,
    search: (rows: number, page: number) => void,
    onGetUserToEdit: (id: string) => void,
    onDeleteUser: (id: string) => void
}

const UsersListComponent = (props: UsersListComponentProps): JSX.Element => {
    const { t } = useTranslation();

    const appConfig = useAppConfig();

    const { userList } = props;
    const [users, setUsers] = useState<UsersInfo[]>(userList.elements || []);

    useEffect(() => {
        setUsers(userList.elements);

    }, [userList]);

    const [loading, setLoading] = useState(false);

    const handleConfirmDelete = (recordId : string) => {
        setLoading(true); 
        onDeleteUser(recordId); 
        setLoading(false); 
      };
 

    const tableColumns: ColumnsType<UsersInfo> = [
        {
            title: t('components.user-list.list.name'),
            dataIndex: "name",
            key: "name",
            render: (item: string, record: UsersInfo) => {
                return <div className="user-name">
                    {record.name} {record.surname} {record.secondSurname}
                </div>
            }
        },
        {
            title: t('components.user-list.list.email'),
            dataIndex: "email",
            key: "email",
            render: (item: string) => item
        },
        {
            title: t('components.user-list.list.phone'),
            dataIndex: "phone",
            key: "phone",
            render: (item: string) => item
        },
        {
            title: t('components.user-list.list.actions'),
            dataIndex: "actions",
            key: "actions",
            render: (item: string, record: UsersInfo) => {
                return <div className="user-actions">
                    <Tooltip title={t('components.user-list.list.tooltip-edit')}>
                        <EditOutlined className="app-icon icon" onClick={() => { onEditUser(record.id) }} />
                    </Tooltip>
                    <Popconfirm
                        title={HTMLReactParser(t('components.user-list.list.delete-confirm', [`${record.name} ${record.surname} ${record.secondSurname}`]))}
                        description={t('components.user-list.list.delete-confirm-description')}
                        onConfirm={() => { handleConfirmDelete(record.id) }}
                        okText={t('components.user-list.list.delete-confirm-yes')}
                        cancelText={t('components.user-list.list.delete-confirm-no')}
                        placement='left'
                        okButtonProps={{ loading }}
                    >
                        <Tooltip title={t('components.user-list.list.tooltip-delete')}>
                            <DeleteOutlined className="app-icon icon danger" style={{ color: "red" }} />
                        </Tooltip>
                    </Popconfirm>

                </div>
            }
        }
    ];

    const [selectedNumRecords, setSelectedNumRecords] = useState<number>(6);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: UsersInfo[]) => {
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    const onEditUser = (id: string): void => {
        props.onGetUserToEdit(id);
    }
    const onDeleteUser = (id: string): void => {
        props.onDeleteUser(id);
    }


    return (
        <div className="user-list">       
            {
                appConfig.device !== "mobile" ?
                    <Table className="app-table" columns={tableColumns} dataSource={users} pagination={false} rowKey={"id"} />
                    :
                    <List
                        className={`app-list`}
                        itemLayout="vertical"
                        dataSource={userList.elements}
                        renderItem={(item: UsersInfo) => 
                            <List.Item className='user-list-item'>
                                <Row gutter={0}>
                                    <Col xs={20} className="list-item user-name" >
                                        <span className="user-name">{item.name.toUpperCase()} {item.surname.toUpperCase()} {item.secondSurname.toUpperCase()}  </span>
                                    </Col>
                                    <Col xs={4} className="list-item flex-center" >
                                        <div className="user-actions">
                                            <Tooltip title={t('components.user-list.list.tooltip-edit')}>
                                                <EditOutlined className="app-icon icon" onClick={() => { onEditUser(item.id) }} />
                                            </Tooltip>
                                            <Popconfirm
                                                title={HTMLReactParser(t('components.user-list.list.delete-confirm', [`${item.name} ${item.surname} ${item.secondSurname}`]))}
                                                description={t('components.user-list.list.delete-confirm-description')}
                                                onConfirm={() => { onDeleteUser(item.id) }}
                                                okText={t('components.user-list.list.delete-confirm-yes')}
                                                cancelText={t('components.user-list.list.delete-confirm-no')}
                                                placement='left'
                                            >
                                                <Tooltip title={t('components.user-list.list.tooltip-delete')}>
                                                    <DeleteOutlined className="app-icon icon danger" style={{ color: "red" }} />
                                                </Tooltip>
                                            </Popconfirm>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="list-additional-info">
                                    <Col xs={24} className="list-item">
                                        <strong>{t('components.user-list.email')}: </strong>
                                        <span>{item.email}</span>
                                    </Col>
                                    <Col xs={24} className="list-item">
                                        <strong>{t('components.user-list.phone')}: </strong>
                                        <span>{item.phone}</span>
                                    </Col>
                                </Row>

                            </List.Item>
                        }
                    />
            }

            <div className="total-records">
                <div className="pagination">
                    <Pagination
                        locale={GetPaginationLocale(t)}
                        total={userList.totalRecords}
                        pageSizeOptions={["6", "20", "50", "100"]}
                        current={selectedPage}
                        pageSize={selectedNumRecords}
                        defaultPageSize={6}
                        defaultCurrent={1}
                        onChange={(page, numRecords) => {
                            setSelectedPage(page);
                            setSelectedNumRecords(numRecords);
                        }}
                    />

                </div>
                <div className="counter">
                    {t('components.user-list.total-records', [userList.totalRecords])}                
                </div>
            </div>
        </div>
    )
}

export default UsersListComponent;