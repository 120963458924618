import { Divider, Menu, MenuProps } from "antd";
import { AppstoreOutlined, GroupOutlined, HomeOutlined, MailOutlined, SearchOutlined, UngroupOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


import './PrivateMenuComponent.scss'
import { GetFormattedUrl } from "../../../utils/urls";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { CurrencySelector, LanguageSelector } from "..";
import { useEffect, useState } from "react";
import { use } from "echarts";
import { useSessionData } from "../../../Contexts/SessionDataContext";

interface PrivateMenuComponentProps {
  onSelectItem: (key: string) => void;
}

const PrivateMenuComponent = (props: PrivateMenuComponentProps) : JSX.Element => {
    const navigate = useNavigate();
    const appConfig = useAppConfig();
    const userSessionData = useSessionData();
    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
      label: React.ReactNode,
      key: React.Key,
      icon?: React.ReactNode,
      children?: MenuItem[],
      type?: 'group',
    ): MenuItem {
      return {
        key,
        icon,
        children,
        label,
        type,
      } as MenuItem;
    }
    const showUsersMenu = ( userSessionData.userData.role == 'super_admin' || userSessionData.userData.role == 'wa_admin') 
                          && (userSessionData.userData.typeAccount == 'agency_agent' || userSessionData.userData.typeAccount == 'event_organizer' );

    const expandedIcons : MenuItem[] = [
      getItem('', 'currency-selector', <LanguageSelector />),
      getItem('', 'currency-selector', <CurrencySelector />),
      { type: 'divider' }
    ];

    const itemsForTabletAndDesktop: MenuItem[] = [
        getItem('Inicio', 'dashboard', <HomeOutlined className='app-icon'/>),
        getItem('Reservar', 'booking-engine', <SearchOutlined className='app-icon'/>),
        ...(showUsersMenu ? [getItem('Usuarios', 'users', <UserOutlined className='app-icon'/>)] : []),

        getItem('Consultar reservas', 'booking-page', <UnorderedListOutlined className='app-icon'/>),
      
        getItem('Contacto', 'sub2', <MailOutlined className='app-icon'/>, [
            getItem('Formulario contacto', 'contact-page', <UngroupOutlined className='app-icon'/>),
            getItem('Formulario de grupos y convenciones', 'groups-and-conventions', <GroupOutlined className='app-icon'/>),
          ]),
    
          getItem('Páginas error', 'sub3', <AppstoreOutlined className='app-icon'/>, [
            getItem('403', '403'),
            getItem('404', '404'),
            getItem('500', '500'),
            getItem('Loading...', 'loading-page')
            ]),
    ];

    const itemsForMobile: MenuItem[] = [
      ...expandedIcons,
      ...itemsForTabletAndDesktop
    ];

    const goPage = (page: string) : void => {
        navigate(`${GetFormattedUrl(`private/${page}`)}`);
    }

    
    const [selectedKey, setSelectedKey] = useState<string>('dashboard');

    //Get the last part of the url to set the selected menu item
    useEffect(() => {
      const url = window.location.href;
      const lastPart = url.split('/').pop();
      console.log(lastPart);
      if (lastPart) {
        setSelectedKey(lastPart);
      }
    }, [window.location.href]);

    return(<div className="private-menu">
            <Menu
                  className="menu"
                  defaultSelectedKeys={['dashboard']}
                  selectedKeys={[selectedKey]}
                  defaultOpenKeys={['sub1']}
                  mode={appConfig.device === "mobile" ? "inline" : "vertical"}
                  items={appConfig.device === "mobile" ? itemsForMobile : itemsForTabletAndDesktop}
                  onClick={(e)=>{
                    if (e.key !== 'currency-selector' && e.key !== 'language-selector'){
                      goPage(e.key)
                      props.onSelectItem(e.key);
                    }
                  }}
              />
          </div>)
}

export default PrivateMenuComponent;

