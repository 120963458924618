import { createContext, useContext, useEffect, useState } from "react"
import AppConfig from "../models/AppConfig";
import { CartReservationInfo, CartRoomData, CartServiceData, EngineKeyValuePair } from "@beds2b-group/reusable-components/dist/types";


export interface CartContextData {
    data: CartReservationInfo,
    updateContext: (newCartStatus: CartReservationInfo) => void,
    addRoomToCart: (room: CartRoomData) => void,
    removeRoomFromCart: (roomIndex: number) => void,
    addServiceToCart: (service: CartServiceData) => void,
    removeServiceFromCart: (serviceIndex: number) => void,
    whatIsTheNextRoomIndex: () => number,
    setHotelData: (hotelData: {
        address: string,
        code: string,
        name: string,
        email: string,
        mainImage: string,
        personOfContact: string,
        phone: string,
        website: string,
        otherInfo: Array<EngineKeyValuePair<string, any>>
    }) => void,
    setDates: (dates: {checkIn: Date, checkOut: Date})=> void,
    clearCart: () => void,
    updateHashCart: (hash: string) => void,
}


const defaultCartValue: CartContextData = {
    data: {
        cartHash: "",
        hotelData: {
            address: "",
            code: "",
            name: "",
            email: "",
            mainImage: "",
            personOfContact: "",
            phone: "",
            website: "",
            otherInfo: [],
        },
        dates: {
            checkIn: new Date(),
            checkOut: new Date(),
        },
        rooms: [],
        services: []
    },
    updateContext: () => { },
    addRoomToCart: () => { },
    removeRoomFromCart: () => { },
    addServiceToCart: () => { },
    removeServiceFromCart: () => { },
    whatIsTheNextRoomIndex: () => { return 1; },
    setHotelData: () => {},
    setDates: () => {},
    clearCart: () => {},
    updateHashCart: () => {},
};


const CartContext = createContext<CartContextData>(defaultCartValue);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cartStatus, setCartStatus] = useState<CartContextData>(defaultCartValue);

    const clearCart = () => {
        defaultCartValue.data = {
            cartHash: "",
            hotelData: {
                address: "",
                code: "",
                name: "",
                email: "",
                mainImage: "",
                personOfContact: "",
                phone: "",
                website: "",
                otherInfo: [],
            },
            dates: {
                checkIn: new Date(),
                checkOut: new Date(),
            },
            rooms: [],
            services: []
        };
        
        setCartStatus(defaultCartValue);
    }

    const updateContext = (newCartStatus: CartReservationInfo) => {
        let newCartStatusCopy = { ...cartStatus };
        newCartStatusCopy.data = newCartStatus;
        setCartStatus(newCartStatusCopy);
    }

    
    const setHotelData = (hotelData: {  code: string,
                                        name: string,
                                        address: string,
                                        email: string,
                                        mainImage: string,
                                        personOfContact: string,
                                        phone: string,
                                        website: string,
                                        otherInfo: Array<EngineKeyValuePair<string, any>>}) => {
        let newCartStatusCopy = { ...cartStatus };
        newCartStatusCopy.data.hotelData = hotelData;
        setCartStatus(newCartStatusCopy);
    }

    const setDates = (dates: {checkIn: Date, checkOut: Date}) => {
        let newCartStatusCopy = { ...cartStatus };
        newCartStatusCopy.data.dates = {
            checkIn: dates.checkIn,
            checkOut: dates.checkOut,
        };
        setCartStatus(newCartStatusCopy);
    }

    const addRoomToCart = (room: CartRoomData) => {
        const newCartStatus = { ...cartStatus };

        //Si hay una habitación en el mismo room.roomIndex la reemplazamos
        const roomIndex = newCartStatus.data.rooms.findIndex(item => item.roomIndex === room.roomIndex);
        if (roomIndex !== -1) {
            // Existe una, así que la reemplazamos
            newCartStatus.data.rooms[roomIndex] = room;
            setCartStatus(newCartStatus);
            return;
        }else{
            // No existe, así que la añadimos
            newCartStatus.data.rooms.push(room);
        }

        //Ordenamos las habitaciones por roomIndex
        newCartStatus.data.rooms.sort((a, b) => a.roomIndex - b.roomIndex); 

        setCartStatus(newCartStatus);
        
    };

    const removeRoomFromCart = (roomIndex: number) => {
        const newCartStatus = { ...cartStatus };
        newCartStatus.data.rooms = newCartStatus.data.rooms.filter(room => room.roomIndex !== roomIndex);
        setCartStatus(newCartStatus);
    };

    const addServiceToCart = (service: CartServiceData) => {
        const newCartStatus = { ...cartStatus };
        newCartStatus.data.services.push(service);
        setCartStatus(newCartStatus);
    }

    const removeServiceFromCart = (serviceIndex: number) => {
        const newCartStatus = { ...cartStatus };
        newCartStatus.data.services = newCartStatus.data.services.filter(service => service.serviceIndex !== serviceIndex);
        setCartStatus(newCartStatus);
    }

    const whatIsTheNextRoomIndex = () : number => {
        let nextRoomIndex = 1;

        cartStatus.data.rooms.forEach(room => {
            if (room.roomIndex == nextRoomIndex){
                nextRoomIndex++;
            }
        });

        console.log("whatIsTheNextRoomIndex in CartContext", nextRoomIndex);

        return nextRoomIndex;
    }

    const updateHashCart = (hash: string) => {
        let newCartStatus = { ...cartStatus };
        newCartStatus.data.cartHash = hash;
        setCartStatus(newCartStatus);
    }

    const contextValue = { ...cartStatus, clearCart, updateContext, updateHashCart, addRoomToCart, removeRoomFromCart, addServiceToCart, removeServiceFromCart, whatIsTheNextRoomIndex, setHotelData, setDates}

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export const useCart = () : CartContextData => useContext(CartContext);
