import BookingInfo from "../models/BookingInfo";
import PostponedBooking from "../models/PostponedBooking";

export const examplePostponedBookings: PostponedBooking[] = [
    {
        bookingId: "1a",
        locator: "ABC123",
        hotelId: "h1",
        hotelName: "Ejemplo Hotel",
        hotelPhone: "+123456789",
        hotelAddress: "123 Calle Principal",
        hotelEmail: "info@examplehotel.com",
        ownerName: "John Doe Doe",
        ownerEmail: "ownner@beds2b.com",
        ownerPhone: "+987654321",
        creationDate: new Date(),
        checkIn: new Date("2024-01-22"),
        checkOut: new Date("2024-01-25"),
        pvp: 150,
        commission: 15,
        currencySymbol: "$",
        currency: "USD",
        userId: "u1",
        userName: "Usuario Ejemplo",
        userEmail: "user@example.com",
        expirationDate: new Date("2024-02-01"),
        nearExpirationDate: true
    },
    {
      bookingId: "2b",
      locator: "XYZ789",
      hotelId: "h2",
      hotelName: "Otro Hotel",
      hotelPhone: "+987654321",
      hotelAddress: "456 Calle Secundaria",
      hotelEmail: "info@anotherhotel.com",
      ownerName: "John Doe Doe",
      ownerEmail: "ownner@beds2b.com",
        ownerPhone: "+987654321",
      creationDate: new Date(),
      checkIn: new Date("2024-02-01"),
      checkOut: new Date("2024-02-05"),
      pvp: 200,
      commission: 20,
      currencySymbol: "€",
      currency: "EUR",
      userId: "u2",
      userName: "Otro Usuario",
      userEmail: "anotheruser@example.com",
      expirationDate: new Date("2024-02-15"),
      nearExpirationDate: true
    },
    {
      bookingId: "3c",
      locator: "DEF456",
      hotelId: "h3",
      hotelName: "Hotel Fantástico",
      hotelPhone: "+111222333",
      hotelAddress: "789 Calle Imaginaria",
      hotelEmail: "info@fantastichotel.com",
      ownerName: "John Doe Doe",
      ownerEmail: "ownner@beds2b.com",
        ownerPhone: "+987654321",
      creationDate: new Date(),
      checkIn: new Date("2024-03-01"),
      checkOut: new Date("2024-03-05"),
      pvp: 180,
      commission: 18,
      currencySymbol: "£",
      currency: "GBP",
      userId: "u3",
      userName: "Usuario Fantástico",
      userEmail: "fantasticuser@example.com",
      expirationDate: new Date("2024-03-15"),
      nearExpirationDate: false
  },
  {
      bookingId: "4d",
      locator: "GHI789",
      hotelId: "h4",
      hotelName: "Hotel Exclusivo",
      hotelPhone: "+444555666",
      hotelAddress: "101 Calle Exclusiva",
      hotelEmail: "info@exclusivehotel.com",
      ownerName: "John Doe Doe",
      ownerEmail: "ownner@beds2b.com",
        ownerPhone: "+987654321",
      creationDate: new Date(),
      checkIn: new Date("2024-04-01"),
      checkOut: new Date("2024-04-10"),
      pvp: 250,
      commission: 25,
      currencySymbol: "¥",
      currency: "JPY",
      userId: "u4",
      userName: "Usuario Exclusivo",
      userEmail: "exclusiveuser@example.com",
      expirationDate: new Date("2024-04-20"),
      nearExpirationDate: false
  },
  {
      bookingId: "5e",
      locator: "JKL012",
      hotelId: "h5",
      hotelName: "Hotel Aventura",
      hotelPhone: "+777888999",
      hotelAddress: "202 Calle de la Aventura",
      hotelEmail: "info@adventurehotel.com",
      ownerName: "John Doe Doe",
      ownerEmail: "ownner@beds2b.com",
        ownerPhone: "+987654321",
      creationDate: new Date(),
      checkIn: new Date("2024-05-01"),
      checkOut: new Date("2024-05-07"),
      pvp: 120,
      commission: 12,
      currencySymbol: "₹",
      currency: "INR",
      userId: "u5",
      userName: "Usuario Aventura",
      userEmail: "adventureuser@example.com",
      expirationDate: new Date("2024-05-15"),
      nearExpirationDate: false
  }
];

export const exampleBookings: BookingInfo[] = [
    {
      bookingId: "B001",
      locator: "LOC123",
      hotelId: "H001",
      hotelName: "Sunset Beach Resort",
      ownerName: "John Doe Doe",
      isCancelled: false,
      statusName: "Reserved",
      checkIn: "2023-10-15",
      checkOut: "2023-10-20",
      creationDate: "2023-10-10",
      creationUser: "Alice Johnson",
      creationUserEmail: "alice@example.com",
      pvp: 150,
      commission: 10,
      payed: 140,
      currencySymbol: "$",
      currency: "USD",
      userId: "U001",
      userName: "David Brown",
      userEmail: "david@example.com",
    },
    {
      bookingId: "B002",
      locator: "LOC456",
      hotelId: "H002",
      hotelName: "Mountain View Lodge",
      ownerName: "John Doe Doe",
      isCancelled: false,
      statusName: "Checked In",
      checkIn: "2023-11-01",
      checkOut: "2023-11-07",
      creationDate: "2023-10-25",
      creationUser: "Bob Wilson",
      creationUserEmail: "bob@example.com",
      pvp: 200,
      commission: 15,
      payed: 185,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U002",
      userName: "Emily Davis",
      userEmail: "emily@example.com",
    },
    {
      bookingId: "B003",
      locator: "LOC789",
      hotelId: "H003",
      hotelName: "Seaside Paradise Hotel",
      ownerName: "John Doe Doe",
      isCancelled: true, 

      statusName: "Cancelled", 
      cancelledDate: new Date(),
      checkIn: "2023-12-05",
      checkOut: "2023-12-10",
      creationDate: "2023-11-20",
      creationUser: "David Johnson",
      creationUserEmail: "david@example.com",
      pvp: 120,
      commission: 5,
      payed: 115,
      currencySymbol: "£",
      currency: "GBP",
      userId: "U003",
      userName: "Olivia Martinez",
      userEmail: "olivia@example.com",
    },
    {
      bookingId: "B004",
      locator: "LOC012",
      hotelId: "H004",
      hotelName: "City Lights Inn",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Checked Out",
      checkIn: "2023-09-20",
      checkOut: "2023-09-25",
      creationDate: "2023-09-15",
      creationUser: "Linda Clark",
      creationUserEmail: "linda@example.com",
      pvp: 180,
      commission: 12,
      payed: 168,
      currencySymbol: "$",
      currency: "USD",
      userId: "U004",
      userName: "Chris Johnson",
      userEmail: "chris@example.com",
    },
    {
      bookingId: "B005",
      locator: "LOC345",
      hotelId: "H005",
      hotelName: "Riverside Retreat",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Reserved",
      checkIn: "2023-11-10",
      checkOut: "2023-11-15",
      creationDate: "2023-11-05",
      creationUser: "Sarah Smith",
      creationUserEmail: "sarah@example.com",
      pvp: 160,
      commission: 10,
      payed: 150,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U005",
      userName: "Mark Wilson",
      userEmail: "mark@example.com",
    },
    {
      bookingId: "B006",
      locator: "LOC678",
      hotelId: "H006",
      hotelName: "Golden Sands Resort",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Reserved",
      checkIn: "2023-12-12",
      checkOut: "2023-12-17",
      creationDate: "2023-12-05",
      creationUser: "James Brown",
      creationUserEmail: "james@example.com",
      pvp: 220,
      commission: 18,
      payed: 202,
      currencySymbol: "$",
      currency: "USD",
      userId: "U006",
      userName: "Laura Johnson",
      userEmail: "laura@example.com",
    },
    {
      bookingId: "B007",
      locator: "LOC901",
      hotelId: "H007",
      hotelName: "Valley Retreat Lodge",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Checked Out",
      checkIn: "2023-11-05",
      checkOut: "2023-11-10",
      creationDate: "2023-10-30",
      creationUser: "Maria Hernandez",
      creationUserEmail: "maria@example.com",
      pvp: 175,
      commission: 12,
      payed: 163,
      currencySymbol: "£",
      currency: "GBP",
      userId: "U007",
      userName: "William Smith",
      userEmail: "william@example.com",
    },
    {
      bookingId: "B008",
      locator: "LOC234",
      hotelId: "H008",
      hotelName: "Hillside Inn",
      ownerName: "John Doe Doe",
      isCancelled: true, 
      statusName: "Cancelled", cancelledDate: new Date(),
      checkIn: "2023-10-25",
      checkOut: "2023-10-30",
      creationDate: "2023-10-20",
      creationUser: "Patricia Wilson",
      creationUserEmail: "patricia@example.com",
      pvp: 210,
      commission: 16,
      payed: 194,
      currencySymbol: "$",
      currency: "USD",
      userId: "U008",
      userName: "Thomas Brown",
      userEmail: "thomas@example.com",
    },
    {
      bookingId: "B009",
      locator: "LOC567",
      hotelId: "H009",
      hotelName: "Beachside Resort",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Checked In",
      checkIn: "2023-11-15",
      checkOut: "2023-11-20",
      creationDate: "2023-11-10",
      creationUser: "Nancy Davis",
      creationUserEmail: "nancy@example.com",
      pvp: 155,
      commission: 10,
      payed: 145,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U009",
      userName: "Michael Brown",
      userEmail: "michael@example.com",
    },
    {
      bookingId: "B010",
      locator: "LOC123",
      hotelId: "H010",
      hotelName: "Forest Haven Inn",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Reserved",
      checkIn: "2023-12-22",
      checkOut: "2023-12-27",
      creationDate: "2023-12-15",
      creationUser: "Laura Wilson",
      creationUserEmail: "laura@example.com",
      pvp: 185,
      commission: 13,
      payed: 172,
      currencySymbol: "$",
      currency: "USD",
      userId: "U010",
      userName: "Nancy Davis",
      userEmail: "nancy@example.com",
    },
    {
      bookingId: "B011",
      locator: "LOC456",
      hotelId: "H011",
      hotelName: "Lakeview Resort",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Reserved",
      checkIn: "2023-10-10",
      checkOut: "2023-10-15",
      creationDate: "2023-10-05",
      creationUser: "Chris Johnson",
      creationUserEmail: "chris@example.com",
      pvp: 195,
      commission: 14,
      payed: 181,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U011",
      userName: "Olivia Smith",
      userEmail: "olivia@example.com",
    },
    {
      bookingId: "B012",
      locator: "LOC789",
      hotelId: "H012",
      hotelName: "Downtown Suites",
      ownerName: "John Doe Doe",
      isCancelled: false,
      statusName: "Checked Out",
      checkIn: "2023-11-30",
      checkOut: "2023-12-05",
      creationDate: "2023-11-25",
      creationUser: "Kevin Brown",
      creationUserEmail: "kevin@example.com",
      pvp: 170,
      commission: 12,
      payed: 158,
      currencySymbol: "$",
      currency: "USD",
      userId: "U012",
      userName: "Daniel Smith",
      userEmail: "daniel@example.com",
    },
    {
      bookingId: "B013",
      locator: "LOC012",
      hotelId: "H013",
      hotelName: "Harbor View Hotel",
      ownerName: "John Doe Doe",
      isCancelled: false, 
      statusName: "Reserved",
      checkIn: "2023-12-08",
      checkOut: "2023-12-13",
      creationDate: "2023-12-01",
      creationUser: "Joseph Wilson",
      creationUserEmail: "joseph@example.com",
      pvp: 155,
      commission: 10,
      payed: 145,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U013",
      userName: "Emily Smith",
      userEmail: "emily@example.com",
    },
    {
      bookingId: "B014",
      locator: "LOC345",
      hotelId: "H014",
      hotelName: "Palm Oasis Resort",
      ownerName: "John Doe Doe",
      isCancelled: true,
      statusName: "Cancelled", cancelledDate: new Date(),
      checkIn: "2023-11-25",
      checkOut: "2023-11-30",
      creationDate: "2023-11-20",
      creationUser: "Joseph Brown",
      creationUserEmail: "joseph@example.com",
      pvp: 200,
      commission: 16,
      payed: 184,
      currencySymbol: "$",
      currency: "USD",
      userId: "U014",
      userName: "William Davis",
      userEmail: "william@example.com",
    },
    {
      bookingId: "B015",
      locator: "LOC678",
      hotelId: "H015",
      hotelName: "Mountain Chalet Inn",
      ownerName: "John Doe Doe",
      isCancelled: false,
      statusName: "Checked In",
      checkIn: "2023-10-15",
      checkOut: "2023-10-20",
      creationDate: "2023-10-10",
      creationUser: "Chris Wilson",
      creationUserEmail: "chris@example.com",
      pvp: 175,
      commission: 11,
      payed: 164,
      currencySymbol: "€",
      currency: "EUR",
      userId: "U015",
      userName: "Olivia Davis",
      userEmail: "olivia@example.com",
    },
  ];