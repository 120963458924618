import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Organization'

export const OrganizationEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("CreateOrganization"),

]