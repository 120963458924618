import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Regimes'

export const RegimesEP : EndPointConfig[] =[
        
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetRegimes"),   
         
]

