import { HotelProperties, RibbonError } from "@beds2b-group/reusable-components/dist/types"


export var clientHotelList = [
  {id:"12" , code: "12341", name: "Maravilla del Mar Resort", countryName: "Españita", city: "Almería", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/apartamentos-paraiso-playa/", latitude: 36.840, longitude: -2.464, address: "Calle de la Playa, 123, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12342", name: "Esplendor Dorado Suites", countryName: "España", city: "Barcelona", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playacapricho-hotel/", latitude: 36.752, longitude: -2.670, address: "Avenida del Mar, 456, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12343", name: "Oasis del Sol Hotel", countryName: "España", city: "Almería", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playalinda-hotel/", latitude: 36.744, longitude: -2.718, address: "Calle del Sol, 789, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12344", name: "Costa Azul Beach Retreat", countryName: "España", city: "Barcelona", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playadulce-hotel/", latitude: 36.708, longitude: -2.797, address: "Avenida de la Playa, 234, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12345", name: "Jardines del Paraíso Resort", countryName: "España", city: "Almería", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/diverhotel-aguadulce/", latitude: 36.820, longitude: -2.621, address: "Calle de la Diversión, 56, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12346", name: "Serenidad Marina Hotel", countryName: "España", city: "Almería", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playasol-spa-hotel/", latitude: 36.707, longitude: -2.792, address: "Avenida del Agua, 789, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12347", name: "Refugio del Viajero", countryName: "España", city: "Almería", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/diverhotel-roquetas/", latitude: 36.758, longitude: -2.609, address: "Calle de la Roca, 890, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "12348", name: "Brisas del Océano Palace", countryName: "España", city: "Málaga", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/vera-playa-club-hotel/", latitude: 37.230, longitude: -1.778, address: "Calle de la Playa, 567, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "12349", name: "Rincón Tropical Lodge", countryName: "España", city: "Málaga", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/zimbali-playa-spa-hotel/", latitude: 36.743, longitude: -2.695, address: "Calle de la Palmera, 345, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123410", name: "Bella Vista Horizon Hotel", countryName: "España", city: "Málaga", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/puerto-marina-suite-hotel/", latitude: 37.136, longitude: -1.829, address: "Calle de la Marina, 789, Almería", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123411", name: "Estrella del Mar Boutique", countryName: "España", city: "Barcelona", cityCode: "", countryCode: "", link: "https://www.montanyahotel.com/es/", latitude: 41.616, longitude: 1.946, address: "Calle de la Montaña, 123, Barcelona", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123412", name: "Amanecer en el Mar Hotel", countryName: "España", city: "Cádiz", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playaballena-spa-hotel/", latitude: 36.756, longitude: -6.382, address: "Avenida de la Ballena, 456, Cádiz", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123413", name: "Sinfonía del Ocaso Resort", countryName: "España", city: "Cádiz", cityCode: "", countryCode: "", link: "http://www.senatorcadizspahotel.com/", latitude: 36.532, longitude: -6.295, address: "Calle de la Ciudad, 789, Cádiz", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123414", name: "Palmas del Cielo Retreat", countryName: "España", city: "Cádiz", cityCode: "", countryCode: "", link: "https://www.guadacorteparkhotel.com/es/", latitude: 36.186, longitude: -5.450, address: "Calle del Parque, 234, Cádiz", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "123415", name: "Río de Sueños Hotel", countryName: "España", city: "Granada", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playacalida-spa-hotel/", latitude: 36.734, longitude: -3.579, address: "Avenida del Sol, 567, Granada", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123416", name: "Encanto del Pacífico Resort", countryName: "México", city: "Distrito Federal", cityCode: "", countryCode: "", link: "http://www.senatorgranadaspahotel.com/", latitude: 19.432, longitude: -99.133, address: "Calle de la Granada, 890, Distrito Federal", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123417", name: "Aventura Marina Suites", countryName: "España", city: "Granada", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/almunecar-playa-spa-hotel/", latitude: 36.732, longitude: -3.686, address: "Avenida de la Playa, 123, Granada", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123418", name: "Puesta del Sol Paradise", countryName: "México", city: "Distrito Federal", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/apartamentos-playamarina/", latitude: 19.432, longitude: -99.133, address: "Calle de la Marina, 456, Distrito Federal", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123419", name: "Horizonte Sereno Hotel", countryName: "España", city: "Huelva", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playacartaya-spa-hotel/", latitude: 37.235, longitude: -6.927, address: "Avenida del Agua, 567, Huelva", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123420", name: "Escapada Costera Lodge", countryName: "España", city: "Huelva", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playacanela-hotel/", latitude: 37.214, longitude: -7.156, address: "Avenida de la Playa, 789, Huelva", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123421", name: "Cielo Estrellado Resort", countryName: "México", city: "Distrito Federal", cityCode: "", countryCode: "", link: "https://www.beds2brewards.com/hoteles/playamarina-spa-hotel/", latitude: 19.432, longitude: -99.133, address: "Calle de la Playa, 234, Distrito Federal", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123422", name: "Brisas del Coral Hotel", countryName: "España", city: "Huelva", cityCode: "", countryCode: "", link: "http://www.senatorhuelvahotel.com/", latitude: 37.266, longitude: -6.944, address: "Calle de la Ciudad, 456, Huelva", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123423", name: "Playa Encantada Retreat", countryName: "España", city: "Madrid", cityCode: "", countryCode: "", link: "http://www.senatorcastellanahotel.com/", latitude: 40.444, longitude: -3.685, address: "Calle de la Castellana, 123, Madrid", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123424", name: "Místico Oasis Hotel", countryName: "España", city: "Madrid", cityCode: "", countryCode: "", link: "http://www.senatorbarajashotel.com/", latitude: 40.470, longitude: -3.568, address: "Avenida del Barrio, 456, Madrid", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123425", name: "Refugio del Viajero Suites", countryName: "México", city: "Guadalajara", cityCode: "", countryCode: "", link: "http://www.senatormarbellaspahotel.com/", latitude: 20.659, longitude: -103.349, address: "Calle de la Playa, 789, Guadalajara", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123426", name: "Aire Fresco Beachfront", countryName: "España", city: "Málaga", cityCode: "", countryCode: "", link: "http://www.senatorbanusspahotel.com/", latitude: 36.488, longitude: -4.952, address: "Avenida del Mar, 567, Málaga", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "123427", name: "Encanto del Mar Azul", countryName: "España", city: "Mallorca", cityCode: "", countryCode: "", link: "http://www.caleiatalayotspahotel.com/", latitude: 39.589, longitude: 3.333, address: "Calle del Talayot, 123, Mallorca", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123428", name: "Mariposas del Caribe Resort", countryName: "México", city: "Guadalajara", cityCode: "", countryCode: "", link: "https://www.senatorcalamillorhotel.com/es/", latitude: 20.710, longitude: -103.419, address: "Calle de la Playa, 456, Guadalajara", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "123429", name: "Palmas Doradas Suites", countryName: "España", city: "Mallorca", cityCode: "", countryCode: "", link: "https://www.clubsimo.com/es/", latitude: 39.621, longitude: 3.345, address: "Calle del Club, 789, Mallorca", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123430", name: "Costa Serena Beach House", countryName: "España", city: "Mallorca", cityCode: "", countryCode: "", link: "http://www.senatorhr.com", latitude: 38.984, longitude: 1.296, address: "Calle de la Playa, 234, Mallorca", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123431", name: "Arena y Cielo Hotel", countryName: "España", city: "Murcia", cityCode: "", countryCode: "", link: "http://www.senatormarmenorspahotel.com/", latitude: 37.759, longitude: -0.876, address: "Avenida del Golf, 567, Murcia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123432", name: "Horizontes del Pacífico Lodge", countryName: "España", city: "Murcia", cityCode: "", countryCode: "", link: "https://www.caleiamarmenorspahotel.com/", latitude: 37.804, longitude: -0.844, address: "Calle de la Mar, 123, Murcia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123433", name: "Sueños del Océano Hotel", countryName: "España", city: "Murcia", cityCode: "", countryCode: "", link: "http://www.theresidencesmarmenor.com/", latitude: 37.767, longitude: -0.970, address: "Calle de la Residencia, 456, Murcia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "123434", name: "Paraíso Terrenal Suites", countryName: "España", city: "Sevilla", cityCode: "", countryCode: "", link: "http://www.hotelvirgendelosreyes.es/", latitude: 37.398, longitude: -5.983, address: "Avenida de la Virgen, 789, Sevilla", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123435", name: "Rincón del Amanecer Resort", countryName: "España", city: "Valencia", cityCode: "", countryCode: "", link: "http://www.senatorparquecentralhotel.com/", latitude: 39.467, longitude: -0.375, address: "Calle del Parque, 456, Valencia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123436", name: "Secretos del Mar Boutique", countryName: "España", city: "Valencia", cityCode: "", countryCode: "", link: "https://www.senatorgandiaspahotel.com/es/?", latitude: 38.961, longitude: -0.172, address: "Avenida del Spa, 789, Valencia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  { id:"12" ,code: "123437", name: "Encanto Tropical Retreat", countryName: "España", city: "Puerto Plata", cityCode: "", countryCode: "", link: "https://www.senatorpuertoplatasparesort.com/", latitude: 19.795, longitude: -70.688, address: "Avenida del Spa, 789, Valencia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" },
  {id:"12" , code: "123438", name: "Ventanas al Océano Hotel", countryName: "España", city: "Puerto Plata", cityCode: "", countryCode: "", link: "https://www.playabachataresort.com/", latitude: 19.821, longitude: -70.681, address: "Avenida del Spa, 789, Valencia", mainImage: "https://www.beds2brewards.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg" }
];


const toDate = new Date()
toDate.setDate(toDate.getDate() + 2)

const hotel: HotelProperties = {
  code: '1',
  alternativeDays: [
    {
      from: new Date(),
      price: { currency: 'EUR', amount: 200 },
      to: toDate,
    },
    {
      from: new Date(),
      price: { currency: 'EUR', amount: 200 },
      to: toDate,
    },
  ],
  images: ['images/room-example.jpg'],
  name: 'Hotel SolYPlaya',
  price: {
    currency: 'EUR',
    amount: 100,
  },
  services: [
    { icon: 'water-solid', name: 'Piscina' },
    { icon: 'wifi', name: 'Wi-Fi' },
    { icon: 'basket', name: 'Supermercados' },
    { icon: 'umbrella-beach-solid', name: 'Supermercados' },
    { icon: 'luggage-cart-solid', name: 'Servicio de maletas' },
    { icon: 'food', name: 'Bufet libre' },
    { icon: 'water-solid', name: 'Piscina' },
    { icon: 'wifi', name: 'Wi-Fi' },
    { icon: 'basket', name: 'Supermercados' },
    { icon: 'umbrella-beach-solid', name: 'Supermercados' },
    { icon: 'luggage-cart-solid', name: 'Servicio de maletas' },
    { icon: 'food', name: 'Bufet libre' },
  ],
  starts: 4,
  description:
    'Habitaciones perfectamente amuebladas y equipadas con dos camas de 1.35x2, telefono, caja fuerte de alquiler, TC, satelite, calefaccion/aire acondicionado, cuarto de baño completo con secador de pelo, y terraza',
  shortDescription: '2 camas de matrimonio 180cm',
  address: {
    city: 'Madrid',
    country: 'Spain',
    line1: 'Calle Alcala',
    zipCode: '5421',
    line2: 'n2',
    region: 'Comunidad de Madrid',
    geoLocation: {
      latitude: 38.986109,
      longitude: -0.149035,
    },
  },
}

const routes = {
  alternativeDates: '/alternative-dates',
  booking: '/booking',
}

const errorComplete: RibbonError = {
  code: 'completed',
  type: 'error',
  translation: 'Hotel completo para fechas seleccionadas',
  classes: [],
}

const errorWarning: RibbonError = {
  code: 'min',
  type: 'warning',
  translation: 'No se cumple con la estancia mínima',
  classes: [],
}

const hotelUtils = {
  routes,
  hotel,
  clientHotelList,
  errorComplete,
  errorWarning,
}

export default hotelUtils
