import { notification } from 'antd';
import BookingInfo, { BookingFilteData, SmallBookingDataList } from '../models/BookingInfo';
import { ContactFormInfo, GroupFormData } from '../models/ContactFormInfo';
import CreateAgentInfo from '../models/CreateAgentInfo';
import { ErrorInfo } from '../models/ErrorInfo';
import HotelInfo from '../models/HotelInfo';
import { AgencyFilter } from '../models/SmallAgencyData';
import {  CompanyFilter } from '../models/SmallCompanyData';
import StatusInfo from '../models/StatusInfo';
import UsersInfo, { UserFilterData } from '../models/UsersInfo';
import ApiResponse from '../models/api/ApiResponse';
import ParamsApi from '../models/api/ParamsApi';
import RestApiClient from './restApiClient';
import UpdatePersonalData from '../models/UpdatePersonalData';

//Mocks
import { exampleBookings, examplePostponedBookings } from '../mocks/bookings';
import { templateInfo } from '../mocks/templates';
import { AvailabilityRequest } from '../models/availability/AvailabilityRequest';
import AddOrganization from '../models/OrganizationData';
import PerformBooking from '../models/booking/PerformBooking';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

var restApiClient : RestApiClient = new RestApiClient();;

// Si esta variable está a TRUE cuando se produce un error en una llamada API se redirige al usuario a la página de error
const REDIRECT_TO_ERROR_PAGE : boolean = false;


export async function pushErrorNotificationData(errorInfo: ErrorInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushErrorNotificationData", errorInfo);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PushErrorNotification", {
            body:errorInfo
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchClientAppCustomData(forced: boolean) : Promise<ApiResponse | null> {
    try {

        var params : ParamsApi = {
            query:{
                forced: forced
            }
        }

        const response = await restApiClient.fetch("GetCustomAppData", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useDoLogin(username: string, password: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useDoLogin", username, password)

        var params : ParamsApi = {
            body:{
                userData: username,
                password: password
            }
        }

        const response = await restApiClient.fetch("LoginFromAgentsWeb", params);
        return response!;
        
    } catch (error) {
        console.error('Error doing log in :', error);
        return null;
    }
}

export async function useCloseSession(idUser: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: useCloseSession", idUser);

        var params: ParamsApi = {
            query: {
                userId: idUser
            }
        }

        const response = await restApiClient.fetch("Logout", params);
        return response!;

    } catch (error) {
        console.error('Error doing log out :', error);
        return null;
    }
}

export async function useRecoverPassword(email: string) : Promise<ApiResponse | null>{
    try {
    
        const data = {
            email : email
        }
        var params : ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("RecoverPassword", params);
        return response!;
        
    } catch (error) {
        console.error('Error recovering password :', error);
        return null;
    }
}

export async function fetchAgencies(filterData: AgencyFilter) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchAgencies", filterData);

    /*    let r : SmallAgencyDataList = {
            totalRecords: 1500,
            agencies: agenciesList
        };

        if (filterData?.commercialName?.length > 0 && filterData?.commercialName === "no"){
            r = {
                totalRecords: 0,
                agencies: []
            };
        }else if (filterData?.commercialName?.length > 0){
            const coincidences = agenciesList.filter((agency) => agency.name.includes(filterData.commercialName));
            r = {
                totalRecords: coincidences.length,
                agencies: coincidences
            };
        }
        

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start + filterData.numRecords;

        r.agencies = r.agencies.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        }; */

        var params : ParamsApi = {
            query:{
                commercialName: filterData.commercialName,
                address : filterData.address,
                phone : filterData.phone,
                fiscalId : filterData.fiscalId,
                numRecords : filterData.numRecords,
                page : filterData.page
            }
        }

        const response = await restApiClient.fetch("GetAgencies", params);
        return response!;
        
    } catch (error) {
        console.error('Error fetching agencies:', error);
        return null;
    }
}

export async function fetchCompanies(filterData: CompanyFilter) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchCompanies", filterData);

        var params : ParamsApi = {
            query:{
                commercialName: filterData.commercialName,
                address : filterData.address,
                phone : filterData.phone,
                fiscalId : filterData.fiscalId,
                numRecords : filterData.numRecords,
                page : filterData.page
            }
        }

        const response = await restApiClient.fetch("GetCompanies", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function sendSignUpRequest(data: {type: string, id: string, email: string, agentName: string}) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: sendSignUpRequest", data);

   
        var params : ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("RequestSignUpInOrganization", params);
        return response!;
        
    } catch (error) {
        console.error('Error sending request to sign up to organization:', error);
        return null;
    }
}

export async function createOrganization(data : AddOrganization) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: createOrganization", data);

        const response = await restApiClient.fetch("CreateOrganization",  {
            body: data
        });
        return response!;
        
    } catch (error) {
        console.error('Error creating orgnanization:', error);
        return null;
    }
}

export async function pushAgent(data: CreateAgentInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushAgent", data);
    try{
      
        const response = await restApiClient.fetch("register/AgentWebUser", {
            body:data
        });
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function resendVerifyEmail(agentEmail: string)  : Promise<ApiResponse | null>{
    console.warn("Calling to: resendVerifyEmail", agentEmail);
    try{
       
        const data = { email : agentEmail}

        const response = await restApiClient.fetch("ResendVerifyEmail", {
            body:data
        });
        return response!;

    }catch(error){
        console.error('Error resending verify email:', error);
        return null;
    }
}

export async function fetchTemplateInfo() : Promise<ApiResponse | null>{
    console.warn("Calling to: getTemplateInfo");
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: templateInfo,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("getClientTemplate", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function sendContactEmail(data: ContactFormInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: sendContactEmail", data);
    try{
        const response = await restApiClient.fetch("SendContactEmail", {
            body:data
        },  );
        
        return response;

    }catch(error){
        console.error('Error sending contact email:', error);
        return null;
    }
}

export async function fetchUserList(filterData: UserFilterData, typeAccount : string ,idOrganization : string | null) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchUserList", filterData);
    try{
        var params : ParamsApi = {
            query:{
                name : filterData.name || "",
                email : filterData.email || "",
                username : filterData.username || "",
                custom : filterData.custom || [],
                numRecords : filterData.numRecords ,
                page : filterData.page,
                phone : filterData.phone || "",
                idOrganization : idOrganization || "",
                typeAccount : typeAccount,
            }
        }

        const response = await restApiClient.fetch("filter/AgentWebUser", params);
        
        return response;

    }catch(error){
        console.error('Error filtering agent web users data:', error);
        return null;
    }
}


export async function fetchUserInfo(userId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchUserInfo", userId);
    try{

        var params : ParamsApi = {
            query:{
                id: userId
            }
        }

        const response = await restApiClient.fetch("GetAgentWebUserData", params);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function pushNewUser(data: UsersInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushNewUser", data);
    try{
        var params : ParamsApi = {
            body:data
        }
        const response = await restApiClient.fetch("create/AgentWebUser", params);
        
        return response;

    }catch(error){
        console.error('Error creating new user ', error);
        return null;
    }
}

export async function deleteUser(userId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: deleteUser", userId);
    try{


        var params : ParamsApi = {
            query:{
                id: userId
            }
        }

        const response = await restApiClient.fetch("Delete", params);
        
        return response;

    }catch(error){
        console.error('Error deleting user:', error);
        return null;
    }
}

export async function updateUser(userData: UsersInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: updateUser", userData);
    try{

        const data = {
            id: userData.id,
            name: userData.name,
            surname: userData.surname,
            secondSurname: userData.secondSurname ? userData.secondSurname : "",
            email: userData.email,
            phone: userData.phone ? userData.phone : "",
            username : userData.username,
            password : userData.password ? userData.password : "",
            role : userData.role,
            language : userData.language,
            wantToReceiveEmails : userData.wantToReceiveEmails,
            customFields : userData.customFields
        } 

        var params : ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("edit/AgentWebUser", params);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotels() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchHotels");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: [
                { 
                    id: "guid-1",
                    name: "Hotel 1", 
                },
                {
                    id: "guid-2",
                    name: "Hotel 2"
                },
                {
                    id: "guid-3",
                    name: "Hotel 3"
                },
                {
                    id: "guid-4",
                    name: "Hotel 4"
                },
                {
                    id: "guid-5",
                    name: "Hotel 5"
                }
            ] as HotelInfo[],
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetHotels", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}



export async function fetchBookingList(filterData: BookingFilteData) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookingList", filterData);
    try{

        let r : SmallBookingDataList = {
            totalRecords: 1500,
            bookings: exampleBookings as BookingInfo[]
        };

        if (filterData?.locator?.length > 0 && (filterData?.locator === "no" )){
            r = {
                totalRecords: 0,
                bookings: []
            };
        }else if (filterData?.locator?.length > 0 ){
            r = {
                totalRecords: 1,
                bookings: [exampleBookings[0]]
            };
        }

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start  + filterData.numRecords;

        r.bookings = r.bookings.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetBookingList", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function synchronizeBookingStatus(bookingId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: synchronizeBookingStatus", bookingId);
    try{

        await new Promise((resolve) => setTimeout(resolve, 4000));

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("SynchronizeBookingStatus", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookingData(bookingId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookingData", bookingId);
    try{
        var params : ParamsApi = {
            query:{locatorPms: bookingId, locatorIntegration: ''
            }
        }

        const response = await restApiClient.fetch("GetBooking", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function cancellBooking(bookingId: number) : Promise<ApiResponse | null>{
    console.warn("Calling to: cancellBooking", bookingId);
    try{

        notification.success({
            message: "Reserva cancelada correctamente",
            description: "La reserva se ha cancelado correctamente"
        });
        
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "Reserva cancelada correctamente",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("CancelBooking", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function sendEmailToCustomer(bookingId: number) : Promise<ApiResponse | null>{
    console.warn("Calling to: cancellBooking", bookingId);
    try{

        notification.success({
            message: "Operación realizada",
            description: "Se ha enviado correctamente un email al cliente con la información de la reserva"
        });
        
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "Email enviado correctamente",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("SendEmailToCustomer", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchRegimesInfo() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchRegimesInfo");
    try{

    
        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetRegimes", params,  );
        
        return response;

    }catch(error){
        console.error('Error fetching regimes :', error);
        return null;
    }
}

export async function sendEmailGroups(data: GroupFormData) : Promise<ApiResponse | null>{
    console.warn("Calling to: sendEmailGroups", data);
    try{

        var params : ParamsApi = {
            body:data
        }

        const response = await restApiClient.fetch("SendGroupContactEmail", params);
        
        return response;

    }catch(error){
        console.error('Error sending group contact email :', error);
        return null;
    }
}

export async function updateProfilePersonalData(updateData: UpdatePersonalData) : Promise<ApiResponse | null> {
    console.warn("Calling to: updateProfilePersonalData", updateData);
    try {
        // Crear un objeto con `null` 
       
        const data: UpdatePersonalData = {
            id: updateData.id,
            name: updateData.name ?? "",
            surname: updateData.surname ?? "" ,
            secondSurname: updateData.secondSurname ?? "",
            language : updateData.language ?? "",
            email: updateData.email ?? "",
            userName: updateData.userName ?? "",
            password: updateData.password ?? "",
            phone: updateData.phone ?? "",
            rewardsId: updateData.rewardsId ?? "",
            notifyEmail: updateData.notifyEmail
        };

        const params: ParamsApi = {
            body: data
        };

        const response = await restApiClient.fetch("EditUserDataProfile", params);
        return response;
    } catch (error) {
        console.error('Error editing user data profile data:', error);
        return null;
    }
}


export async function fetchLocationAvailability(availabilityData: AvailabilityRequest) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchLocationAvailability", availabilityData);
    try{

        // Mocks en locationAvailabilityResponseExample

        var params : ParamsApi = {
            body:availabilityData
        }

        const response = await restApiClient.fetch("GetLocationAvailability", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotelAvailability(availabilityData: AvailabilityRequest) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchHotelAvailability", availabilityData);
    try{

        // Mocks en roomAvailabilityMock

        var params : ParamsApi = {
            body:availabilityData
        }

        const response = await restApiClient.fetch("GetHotelAvailability", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchUserHistory() : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchUserHistory");
    try{
        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetUserSearchHistory", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotelsDAta() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchHotelsDAta (GetHotels)");
    try{

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetHotels", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchSearchCoincidences(value: string) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchSearchCoincidences", value);
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: [],
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetSearchCoicidences", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchPostponedBookings() : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchPostponedBookings");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: examplePostponedBookings,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("fetchPostponedBookings", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }

}


export async function isValidUsername(username: string): Promise<ApiResponse | null> {
    console.warn("Calling to: checkUsername");
    try {
        var params: ParamsApi = {
            query: {
                username: username
            }
        }

        const response = await restApiClient.fetch("CheckIsValidNewUsername", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}



export async function isValidEmail(email: string): Promise<ApiResponse | null> {
    console.warn("Calling to: checkIsValidEmail");
    try {
        var params: ParamsApi = {
            query: {
                email: email,
                type: "wa"
            }
        }

        const response = await restApiClient.fetch("CheckIsValidEmail", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function performBooking(bookingData: PerformBooking) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: performBooking", bookingData);

        const response = await restApiClient.fetch("PerformBooking", {
            body: bookingData
        });

        return response!;

    } catch (error) {
        console.error('Error performing booking:', error);
        return null;
    }
}




