import { Button, Col, Row, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import BookingData, { BookingLine, Extra, PaxTypeEnum } from '../../../models/BookingData';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';


import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { CancellationPolicies, Occupation } from '../../../components';
import { fetchBookingData, sendEmailToCustomer } from '../../../api/repositoryEP';
import { InfoCircleOutlined, LoadingOutlined, MailOutlined, PrinterOutlined, RollbackOutlined } from '@ant-design/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';

import './BookingPaymentOKPage.scss'
import { useNavigate } from 'react-router-dom';
import { GetCheckCircleIcon } from '../../../utils/icons';

const BookingPaymentOkPage = () : JSX.Element => {

    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const [bookingData, setBookingData] = useState<BookingData>();

    const [loadingLocatorInfo, setLoadingLocatorInfo] = useState<boolean>(true);

    const [netPrice, setNetPrice] = useState<number>(0);
    const [commission, setCommission] = useState<number>(0);
    const [pvpPrice, setPvpPrice] = useState<number>(0);
    const [totalBooking, setTotalBooking] = useState<number>(0);
    const [currencyBooking, setCurrencyBooking] = useState<string>('');

    useEffect(()=>{
        if (bookingData){
            var totalBookingPvp = bookingData.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.pvp, 0);
            var totalBookingNet = bookingData.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.net, 0);
            var totalCommissions = bookingData.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.commission, 0);

            setCommission(totalCommissions);
            setPvpPrice(totalBookingPvp);
            setNetPrice(totalBookingNet);
            setTotalBooking(bookingData.isNetPrice ? totalBookingNet : totalBookingPvp);
            setCurrencyBooking(bookingData.currencyCode);
        }
    }, [bookingData])
    
    useEffect(()=>{
        //Get booking data

        setLoadingLocatorInfo(true);
        const locator = window.location.pathname.split('/').pop();

        if (locator){
            fetchBookingData(locator).then((r) => {
                if (r && r.data){
                    console.log("BookingPaymentOkPage.tsx => fetchBookingData => r.data", r.data);
                    setBookingData(r.data);
                }
            })
            .finally(() => {
                setLoadingLocatorInfo(false);
            });
        }
    },[])


    const center = {
        lat: bookingData?.hotelInfo.latitude ?? 0,
        lng: bookingData?.hotelInfo.longitude ?? 0
    };

    const containerStyle = {
        width: '100%',
        height: '206.359px'
    };

    const { isLoaded } = useJsApiLoader({
                            id: 'google-map-script',
                            googleMapsApiKey: "AIzaSyC34CpfBPB0eXXmONTzlreC2nt7atGRir0"
                        })
    
    const [map, setMap] = useState<google.maps.Map | null>(null)
    
    const onLoad = useCallback(function callback(map: google.maps.Map) {

        map.setZoom(14);

        const markerPosition = { lat: bookingData?.hotelInfo.latitude ?? 0, lng: bookingData?.hotelInfo.longitude ?? 0 };

        const marker = new google.maps.Marker({
            position: markerPosition,
            map: map,
            title: bookingData?.hotelInfo.name, 
          });

        setMap(map)
    }, [bookingData])
    
    const onUnmount = useCallback(function callback(map: any) {
                        setMap(null)
                        }, [])

    
    const navigate = useNavigate();
    const goBack = () : void => {
        navigate(-1);
    }
    
    const [isSendingCopyByEmail, setIsSendingCopyByEmail] = useState<boolean>(false);
    const [isSendingDisabled, setIsSendingDisabled] = useState<boolean>(false);
    const [leftTimeToEnableSendButton, setLeftTimeToEnableSendButton] = useState<number>(0);
    const sendCopyByEmail = () : void => {
        if (bookingData){
            setIsSendingCopyByEmail(true);
            sendEmailToCustomer(bookingData.id).finally(() => {
                setIsSendingCopyByEmail(false);

                //Una vez enviado el correo deshabilitamos el botón durante 30 segundos
                setIsSendingDisabled(true);
                const timeout = setTimeout(() => {
                    setIsSendingDisabled(false);
                    clearInterval(interval);
                }, 31000);

                //Cada segundo actualizamos el tiempo que queda para habilitar el botón
                setLeftTimeToEnableSendButton(30);
                const interval = setInterval(() => {
                    setLeftTimeToEnableSendButton((prev) => {
                        if (prev <= 0) {
                            clearInterval(interval); // Detiene el intervalo cuando el contador llega a 0
                            return 0; // Para evitar valores negativos en el contador
                        }
                        return prev - 1;
                    });
                }, 1000);

            });
        }
    }

    /**Añadimos un manejador para que cuando se pinche sobre un elemento con la clase resume-link se haga scrollo hasta el elemento con el atributo tag que contenga el nombre del id que tiene resume-link */
    useEffect(() => {
        const resumeLinks = document.querySelectorAll(".resume-link");
      
        resumeLinks.forEach((resumeLink: any) => {
            const handleClick = (event: MouseEvent) => {
            event.preventDefault();
            const tag = (event.currentTarget as HTMLAnchorElement)?.getAttribute(
              "id"
            );
            const element = document.querySelector(`[data-tag='${tag}']`);
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }
          };
      
          resumeLink.addEventListener("click", handleClick);
      
          return () => {
            resumeLink.removeEventListener("click", handleClick);
          };
        });
      }, []);


    return (
        <div className="booking-ok-customer-voucher-page">
            <div className="booking-ok-message">
                <div>
                    {
                        GetCheckCircleIcon("app-icon")
                    }
                </div>
                <h1>
                    {t('pages.booking-detail.booking-ok-title')}
                </h1>
                <h2>
                    {t('pages.booking-detail.booking-ok-message', [bookingData?.pmsLocator])}
                </h2>
            </div>
            <div className="print-page">
                <div className="resume print-block">
                        <Row gutter={0} >
                            <Col xs={24} lg={16} className="background-image-resume" style={{backgroundImage: `url(${bookingData?.hotelInfo.mainImage})`}}>
                            </Col>
                            <Col xs={24} lg={8}>
                                {
                                    isLoaded ?<GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={center}
                                                    onLoad={onLoad}
                                                    options={{
                                                        //https://developers.google.com/maps/documentation/javascript/reference?csw=1#MapOptions
                                                        zoomControl: false, // Deshabilita el control de zoom
                                                        streetViewControl: false, // Deshabilita el control de Street View
                                                        mapTypeControl: false, // Deshabilita el control de tipo de mapa
                                                        scaleControl: false, // Deshabilita el control de escala
                                                        rotateControl: false, // Deshabilita el control de rotación
                                                        fullscreenControl: true, // Habilita el control de pantalla completa
                                                    }}
                                                    onUnmount={onUnmount}
                                                >
                                                { /* Child components, such as markers, info windows, etc. */ }

                                                    <Marker title={bookingData?.hotelInfo.name} position={{ lat: bookingData?.hotelInfo.latitude ?? 0, lng: bookingData?.hotelInfo.longitude ?? 0 }} />
                                                <></>
                                                </GoogleMap> : <>Cargando mapa...</>
                                }
                            </Col>
                            <Col xs={24} lg={14} className="content">
                                <h3 className="main-title">
                                    {
                                        t('pages.booking-detail.main-title')
                                    }
                                </h3>
                                <p>
                                    <span>
                                        

                                        {
                                            bookingData?.isCancelled ? 
                                                <div className="text-cancelled-at">
                                                    {t('pages.booking-detail.booking-status.cancelled-at',[bookingData?.lastUpdateDate ? moment(bookingData?.lastUpdateDate).format(appConfig.config.dateFormat) : 'N/D (4)'])}
                                                </div>
                                            :
                                                ''
                                        }
                                            
                                    </span>
                                </p>
                                <h3 className="hotel-name">
                                    {bookingData?.hotelInfo.name}
                                </h3>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-address')}: {bookingData?.hotelInfo.address}
                                </p>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-phone')}: {bookingData?.hotelInfo.phone}
                                </p>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-website')}: <a target='_blank' className="app-link" href={`${bookingData?.hotelInfo.website}`}>{bookingData?.hotelInfo.website}</a>
                                </p>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-director')}: {bookingData?.hotelInfo.director}
                                </p>
                                
                                <p className="booking-header-resume">
                                    <span>
                                        {t('pages.booking-detail.locator')}: {bookingData?.pmsLocator}
                                        <br/>
                                    </span>
                                    <span>
                                        {t('pages.booking-detail.check-in')}:&nbsp;{moment(bookingData?.bookingLines[0].checkIn!.toString()).format(appConfig.config.dateFormat.split(' ')[0])}
                                    </span>
                                    <span>{t('pages.booking-detail.check-out')}:&nbsp;{moment(bookingData?.bookingLines[0].checkOut!.toString()).format(appConfig.config.dateFormat.split(' ')[0])}
                                    <br/>
                                    </span>
                                    <span>
                                        {
                                            HTMLReactParser(
                                                t('pages.booking-detail.room-occupancy', [
                                                    //Total de habitaciones
                                                    bookingData?.bookingLines?.length,
                                                    //Total adultos (aquellos que paxType == adult|teneerger) de todas las habitaciones
                                                    bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes?.filter((pax) => pax.paxType === 0 || pax.paxType === 1)?.length, 0),
                                                    //Total niños (aquellos que paxType == child|infant) de todas las habitaciones
                                                    bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes?.filter((pax) => pax.paxType === 2 || pax.paxType === 3)?.length, 0),
                                                    bookingData?.extras?.length
                                                    ])
                                            )
                                        }

                                        {
                                            //Pintamos un tag especial en función del estado de la reserva

                                        }
                                    </span>
                                </p>
                            </Col>
                            <Col xs={24} lg={10} className="content">
                                <div className="owner-data">
                                    <h3 className="main-title">
                                        {
                                            t('pages.booking-detail.owner-data.title')
                                        }
                                    </h3>
                                    <p className="owner-name">
                                        {t('pages.booking-detail.owner-data.name')}: {bookingData?.ownerData.name} {bookingData?.ownerData.surname} {bookingData?.ownerData.secondSurname}  
                                    </p>
                                    <p className="owner-phone">
                                        {t('pages.booking-detail.owner-data.phone')}: {bookingData?.ownerData.phone ?? '-'}  
                                    </p>
                                    <p className="owner-phone">
                                        {t('pages.booking-detail.owner-data.email')}: {bookingData?.ownerData.email ? <a className='app-link' href={`mailto:${bookingData?.ownerData.email}`}>{bookingData?.ownerData.email}</a> : '-'}  
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} className="content">
                                <div className="total-resume">
                                    <Row gutter={0}>
                                        <Col xs={24} className="total-price">
                                            {t('pages.booking-detail.total-resume.total-price')}: {totalBooking} {currencyBooking}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} className="content">
                            <div className="btn-actions">
                                    <Button className="app-button" onClick={sendCopyByEmail} disabled={isSendingCopyByEmail || isSendingDisabled}>
                                        {
                                            isSendingCopyByEmail ? 
                                                <LoadingOutlined className="loading-icon"/>
                                                :
                                                ''
                                        }
                                        <MailOutlined /> {t('pages.booking-detail.actions.send-copy-to-client')}
                                    </Button>  

                                    <Button className="app-button" onClick={goBack}>
                                        <RollbackOutlined />
                                    </Button>

                                    <Button className="app-button blue-button" onClick={()=>{window.print()}}>
                                        <PrinterOutlined />
                                    </Button>
                                    
                                    
                            </div>
                            <div className="alerts">
                                {
                                    leftTimeToEnableSendButton > 0 ?
                                        <span className="left-time-to-enable-send-button">{t('pages.booking-detail.left-time-to-enable-send-button', [leftTimeToEnableSendButton])}</span>
                                    :
                                        ''
                                }
                            </div>
                            
                            </Col>
                    </Row>
                </div>
                <div className="rooms print-block" data-tag="rooms">
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className="main-title">
                                {
                                    t('pages.booking-detail.rooms-title')
                                }
                            </h3>

                            {
                                bookingData?.bookingLines?.map((bookingLine: BookingLine, index: number) => {
                                    return (
                                        <div className="room">
                                            <Row gutter={0}>
                                                <Tooltip title={bookingLine.roomName}>
                                                    <Col xs={24} lg={4} className="room-image" style={{backgroundImage: `url(${bookingLine.mainImage})`}}>
                                                    </Col>
                                                </Tooltip>
                                                <Col xs={24} lg={15} className="room-details">
                                                    <h4>{HTMLReactParser(t("pages.booking-detail.room-for", [
                                                        index+1,
                                                        bookingLine.roomName,
                                                        bookingLine.paxes?.filter((pax) => pax.paxType === 0)?.length,
                                                        bookingLine.paxes?.filter((pax) => pax.paxType !== 0)?.length]))}</h4>
                                                    <p className="regime-name">{bookingLine.regimeName}</p>

                                                    
                                                    <CancellationPolicies cancellationPolicies={bookingLine.cancellationPolicies ?? []} currencyCode={bookingData.currencyCode}/>

                                                    <Occupation occupation={bookingLine.paxes ?? []} checkOut={bookingLine.checkOut} />
                                                    
                                                </Col>
                                                <Col xs={24} lg={5} className="room-price">
                                                    <span>{bookingData.isNetPrice ? bookingLine.net.toFixed(2) : bookingLine.pvp.toFixed(2)} {bookingData.currencyCode}</span>
                                                </Col>
                                            </Row>
                                            
                                            
                                            
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                {
                    bookingData?.extras && <div className="services print-block" data-tag="extras">
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className='main-title'>
                                {t('pages.booking-detail.extra-services.title')}
                            </h3>

                            <div>
                                {
                                    bookingData?.extras?.map((extra: Extra, index: number) => {
                                        return (
                                            <div className="service">
                                                <Row gutter={0}>
                                                    <Tooltip title={extra.description}>
                                                        <Col xs={24} lg={4} className="image" style={{backgroundImage: `url(${extra.image})`}}>
                                                        </Col>
                                                    </Tooltip>
                                                    <Col xs={24} lg={15} className="description">
                                                        <h4>{index+1}. {extra.description}</h4>
                                                        <p>{t('pages.booking-detail.extra-services.quantity')}: {extra.quantity} </p>
                                                        <p>{t('pages.booking-detail.extra-services.date')}: {moment(extra.applicationDateFrom).format(appConfig.config.dateFormat)} - {moment(extra.applicationDateTo).format(appConfig.config.dateFormat)}</p>
                                                    </Col>
                                                    <Col xs={24} lg={5} className="service-price">
                                                        <span>{extra.price > 0 ? `${extra.price.toFixed(2)} ${bookingData.currencyCode}`: t('pages.booking-detail.extra-services.free')} </span>
                                                </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                }
                
            </div>
        </div>
        )
}

export default BookingPaymentOkPage;