import { Modal, Steps } from "antd";
import { useTranslation } from "react-i18next";

import './PaginationComponent.scss';
import { useState } from "react";


interface PaginationComponentProps {
    actualStep: number,
    onChangeStep: (step: number) => void,
}
/**
 * Una reserva consta de 4 pasos:
 *  - Paso 1 = Búsqueda: Puede ser el resultado de:
 *              - Búsqueda por destino
 *              - Búsqueda por hotel
 *              - Búsqueda vacía (sin resultados)
 *  - Paso 2 = Selección de habitaciones
 *              - Se mantendrá en este paso mientras no se hayan seleccionado todas las habitaciones
 *  - Paso 3 = Datos de cliente
 *             - Se mantendrá en este paso mientras no se hayan introducido todos los datos de cliente
 *  - Paso 4 = Confirmación
 *             - Se mostrará la confirmación de la reserva
 *  */
const PaginationComponent = (props: PaginationComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const { actualStep } = props;
    const [newStep, setNewStep] = useState<number>(3);

    // Traduce los títulos de los pasos
    const searchTitle = t('components.booking-pagination.search');
    const selectionTitle = t('components.booking-pagination.selection');
    const bookingDataTitle = t('components.booking-pagination.booking-data');
    const confirmationTitle = t('components.booking-pagination.confirmation');

    const onChangeStep = (v: number) => {

        if (v == 1){
            setNewStep(v);
            showModal();
        }else{
            goStep(v);
        }
        
    }

    const goStep = (v: number) => {
        props.onChangeStep(v);
    }

    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
    };

    return(
        <div className='pagination-component'>
            <Modal
                className="app-modal"
                title={t('components.booking-pagination.change-step-modal.title-confirmation')}
                open={open}
                onOk={()=>{
                    hideModal();
                    goStep(newStep);
                }}
                onCancel={hideModal}
                okText={t('components.booking-pagination.change-step-modal.ok')}
                cancelText={t('components.booking-pagination.change-step-modal.cancel')}
            >
                <p style={{textAlign: "center", fontSize: "1.2em"}}>
                    {
                        t('components.booking-pagination.change-step-modal.description-confirmation')
                    }
                </p>

                <p style={{textAlign: "center", fontSize: "1.2em"}}>
                    <u><strong>
                        {
                            t('components.booking-pagination.change-step-modal.description-confirmation-2')
                        }
                    </strong></u>
                </p>
            </Modal>
            {
                // Añadimos los elementos para mostrar los 4 pasos y solo estará activo el paso actual
            }

            
            <Steps
                size="small"
                onChange={onChangeStep}
                current={actualStep-1}
                items={[
                    {
                        title: searchTitle,
                    },
                    {
                        title: selectionTitle,
                    },
                    {
                        title: bookingDataTitle,
                    },
                    {
                        title: confirmationTitle,
                    },
                    ]}
            />
        </div>
    )
}

export default PaginationComponent;