import { useTranslation } from "react-i18next";
import { PublicHeader, PublicFooter } from "../../../components/common";
import { useEffect, useState } from "react";
import { ContactPhoneInfo } from "../../../models/AppConfig";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { ContactForm, ContactPhone } from "../../../components";

import './ContactPage.scss';
import ContactPhonesComponent from "../../../components/common/contact/ContactPhonesComponent";
import { ContactFormInfo } from "../../../models/ContactFormInfo";
import { Button, notification } from "antd";
import { sendContactEmail } from "../../../api/repositoryEP";

const PublicContactPage = () : JSX.Element => {

    const { t } = useTranslation();

    const appConfig = useAppConfig();

    
    const [contactPhones, setContactPhones] = useState<ContactPhoneInfo[]>([]);
    const [sendingData, setSendingData] = useState<boolean>(false);
    const [sendStatus, setSendStatus] = useState<'ok' | 'ko' | ''>('');
    const sendEmail = (values: ContactFormInfo) : void => {
        setSendingData(true);
        sendContactEmail(values).then((r)=>{
            if (r?.type === 1){
                setSendStatus('ok');
            }else{
                setSendStatus('ko');
            }
    }).finally(()=>{
            setSendingData(false);
        })
    }


    useEffect(()=>{
        if (appConfig?.config?.contactPhones){
            setContactPhones(appConfig.config.contactPhones);
        }    
    },[appConfig])

    return(
        <div className="contact-page">
                <h3 className="app-main-title">{t('pages.contact-page.title')}</h3>

                <ContactPhonesComponent contactPhoneList={contactPhones} />

                <h3 className="app-main-title">
                    {t('pages.contact-page.other-way')}
                </h3>

                <ContactForm onFinish={sendEmail} sending={sendingData} sendStatus={sendStatus}/>

                <br/>
                <br/>
                <br/>
        </div>
    )
}

export default PublicContactPage;