import { registerModal, useModal } from '@gluedigital/modal'
import { RateRoomModal } from '@beds2b-group/reusable-components'
import { Meal, RoomRate } from '@beds2b-group/reusable-components/dist/types';


interface RoomRateModalWrapperProps{
  roomName: string;
  selectedMeal: Meal;
  rate: RoomRate
}

function RoomRateModalWrapper(roomRateInfo: {rateData: RoomRate, mealData: Meal, roomName: string}) {
  const modal = useModal()

  return (
    <RateRoomModal
      selectedMeal={roomRateInfo.mealData}
      rate={roomRateInfo.rateData}
      roomName={roomRateInfo.roomName}
      handleClose={() => modal.hide()}
    />
  )
}

export default RoomRateModalWrapper

registerModal('room-rate-modal', RoomRateModalWrapper)
