
import { useTranslation } from 'react-i18next';
import './GroupContactPage.scss'
import { GroupContactForm } from '../../../components';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useEffect, useState } from 'react';
import RegimeInfo from '../../../models/RegimeInfo';
import { fetchRegimesInfo, sendEmailGroups } from '../../../api/repositoryEP';
import { GroupFormData } from '../../../models/ContactFormInfo';
import ApiResponse from '../../../models/api/ApiResponse';
import { useSessionData } from '../../../Contexts/SessionDataContext';

const GroupContactPage = () : JSX.Element => {
    const { t } = useTranslation();

    const [regimes, setRegimes] = useState<RegimeInfo[]>([]);

    const appConfig = useAppConfig();
    const userSessionData = useSessionData();

    const [sendingFormData, setSendingFormData] = useState<boolean>(false);
    const [forceResetForm, setForceResetForm] = useState<number>(0);
    const onSubmit = (formData: GroupFormData) => {
        setSendingFormData(true);
        sendEmailGroups(formData).then((r: ApiResponse | null)=>{
            if (r?.type == 1){
                setForceResetForm(forceResetForm + 1);
            }
        }).finally(()=>{
            setSendingFormData(false);
        });
    }

    const getRegimes = () => {

        fetchRegimesInfo().then((response)=>{
            if (response?.type == 1){           
                setRegimes(response.data);
            }
        })
    }

    useEffect(() => {
        if (appConfig?.config?.contactFormParameters) {
            console.log(appConfig.config.contactFormParameters);
            console.log(appConfig.config.contactFormParameters.availableBugetTypes);
        }
        getRegimes();
    }, []);
    

    return(<div className="group-contact-page">
        <h2 className="title">{t('pages.group-contact-page.main-title')}</h2>
        <h3 className="sub-title">{t('pages.group-contact-page.subtitle')}</h3>
        <GroupContactForm 
            onSubmit={onSubmit} 
            hotels={appConfig.config.hotels}
            budgetTypes={appConfig.config.contactFormParameters.availableBugetTypes}
            services={appConfig.config.contactFormParameters.availableServices}
            minOccupancyNumber={appConfig.config.contactFormParameters.minOccupancyNumber}
            maxOccupancyNumber={appConfig.config.contactFormParameters.maxOccupancyNumber}
            minRoomsNumber={appConfig.config.contactFormParameters.minRoomsNumber}
            maxRoomsNumber={appConfig.config.contactFormParameters.maxRoomsNumber}
            release={appConfig.config.contactFormParameters.release}
            regimes={regimes}
            sending={sendingFormData}
            onResetFields={forceResetForm}
            sendCopyToAgent={appConfig.config.contactFormParameters.sendCopyToAgent}
            copyMailTo={appConfig.config.contactFormParameters.mailTo}
            email={userSessionData.userData.email}
            name={userSessionData.userData.name + " " + userSessionData.userData.surname } 
            
        />
    </div>)
}

export default GroupContactPage;