import { createContext, useContext, useEffect, useState } from "react";
import UserSessionData from "../models/UserSessionData";

import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from "../utils/urls";


const defaultSessionData : UserSessionData = {
    "sessionId": "",
    "userData": {
        "id": "",
        "name": "",
        "surname": "",
        "secondSurname": "",
        "UAID": "",
        "avatar": "",
        "email" : "",
        "phone": "",
        "username": "",
        "market": "",
        "role": "",
        "typeAccount": "",
        "idOrganization": "",
        "notifyEmail" : null
    },
    "isLogged": false
}

const SessionDataContext = createContext<UserSessionData>(defaultSessionData);

export const SessionDataProvider : React.FC<{ children: React.ReactNode} > = ({ children }) => {
    const [sessionInfo, setSessionInfo] = useState<UserSessionData>(defaultSessionData);

    const checkPage = () : void => {
        var path = window.location.pathname;
        const lang : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();

        if (path.indexOf("private") !== -1){
            //Está en una página privada, le enviamos a la página de 403
            window.location.href = `${lang}/forbiden/no-session-found`;
        }
        // EOC está en una página pública, no pasa nada.
    }

    useEffect(()=>{
        const sessionData = sessionStorage.getItem("user-data");
        console.log("datos de la sesion", sessionData);

        if (sessionData){
            setSessionInfo(JSON.parse(sessionData) as UserSessionData);
        }else{
            checkPage();
        }
    },[]);

    return(
        <SessionDataContext.Provider value={sessionInfo}>
            {children}
        </SessionDataContext.Provider>
    )
}

export const useSessionData = () : UserSessionData => useContext(SessionDataContext);