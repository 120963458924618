import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AppDebuggerComponent.scss";
import { FloatButton, Input, notification } from "antd";
import { AlignLeftOutlined, BorderBottomOutlined, BorderHorizontalOutlined, BorderOuterOutlined, BorderTopOutlined, BorderVerticleOutlined, BugOutlined, ClearOutlined, CloseOutlined, ColumnWidthOutlined, CopyOutlined, DesktopOutlined, FullscreenExitOutlined, FullscreenOutlined, FundProjectionScreenOutlined, MobileOutlined, SearchOutlined, ShoppingCartOutlined, TabletOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppConfig } from "../../Contexts/AppConfigContext";
import { useBookingEngineContext } from "../../Contexts/EngineDataContext";
import { useCart } from "../../Contexts/CartContexts";

interface ConsoleMessage {
  type: string;
  message: string;
  jsonInfo: any;
  datetime: Date;
}

const AppDebuggerComponent: React.FC = () => {
  const appConfig = useAppConfig(); 
  const bookingEngineContext = useBookingEngineContext();
  const cartContext = useCart();
  const [maxLogItems, setMaxLogItems] = useState<number>(50);
  const [isVisible, setIsVisible] = useState(false);
  const [consoleOutput, setConsoleOutput] = useState<ConsoleMessage[]>([]);
  const forceUpdate = useForceUpdate(); // Implementa useForceUpdate como se muestra abajo
  const [isDragging, setIsDragging] = useState(false);
  const [consoleWeight, setConsoleWeight] = useState(window.innerWidth);
  const [consoleHeight, setConsoleHeight] = useState(window.innerHeight / 2);
  const [messageCounter, setMessageCounter] = useState(0);
  const [filter, setFilter] = useState('');

  const logContainerRef = useRef<HTMLUListElement>(null);

  const scrollToBottom = () => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const originalConsoleLog = console.log;
    const originalConsoleWarn = console.warn;
    const originalConsoleError = console.error;

    const addLog = (type: string, args: any[]) => {
        let jsonInfo = '';
        const message = args.join(" ");

        if(message.indexOf('[object Object]') !== -1){
          jsonInfo = JSON.stringify(args);
        }

        setConsoleOutput((prevOutput) => [
          ...prevOutput.slice(-((maxLogItems+1) - args.length)), // Mantener los últimos 49 mensajes
          { type, message: message, jsonInfo: jsonInfo, datetime: new Date() },
        ]);
        setMessageCounter((prevCounter) => prevCounter + 1);
        forceUpdate();
        scrollToBottom();
      };

      console.log = (...args: any[]) => {
        addLog("log", args);
        originalConsoleLog(...args);
      };
    
      console.warn = (...args: any[]) => {
        addLog("warn", args);
        originalConsoleWarn(...args);
      };
    
      console.error = (...args: any[]) => {
        addLog("error", args);
        originalConsoleError(...args);
      };

    return () => {
      console.log = originalConsoleLog;
      console.warn = originalConsoleWarn;
      console.error = originalConsoleError;
    };
  }, [forceUpdate]);

  const toogleVisible = () => {
    setMessageCounter(0);
    setIsVisible(!isVisible);
    setTimeout(() => {
        scrollToBottom();
    }, 100);
  };

  const showBookingContextInfo = () => {
    console.log("Booking Engine Context Data", bookingEngineContext);
  }

  const showCartContextInfo = () => {
    console.log("Cart Context Data", cartContext);
  }

  const clearConsole = () => {
    setConsoleOutput([]);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const changeScreenMode = (mode: string) => {
    switch(mode){
      case 'full':
        setConsoleHeight(window.innerHeight - 70);
        break;
      case 'min':
        setConsoleHeight(110);
        break;
      case 'med':
        setConsoleHeight(window.innerHeight / 2);
        break;
      case 'med-col':
        setConsoleWeight(window.innerWidth / 2);
        break;
      case 'full-col':
        setConsoleWeight(window.innerWidth);
        break;
      default:
        setConsoleHeight(window.innerHeight / 2);
        break;
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      const newHeight = Math.max(
        100,
        Math.min(window.innerHeight - 70, window.innerHeight - e.clientY)
      );
      setConsoleHeight(newHeight);
    }
  };

  const copyToClipBoard = (output: ConsoleMessage) => {

    const text = output.jsonInfo && output.jsonInfo.length > 0 ? output.jsonInfo : output.message;

    navigator.clipboard.writeText(text).then(()=>{
      notification.success({
        message: 'Copied to clipboard',
        description: 'The text has been copied to the clipboard.'
      });
    });
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove as any);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove as any);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove as any);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const getDeviceIcon = () => {
    switch(appConfig.device){
      case "mobile":
        return <MobileOutlined className="app-icon"/>;
      case "tablet":
        return <TabletOutlined className="app-icon"/>;
      default:
        return <DesktopOutlined className="app-icon"/>;
    }
  }

  return (
    <>
      {isVisible ? (
        <div
          className={`app-debugger-console ${isVisible ? "visible" : "hidden"}`}
          style={{ height: `${consoleHeight}px`, width: `${consoleWeight}px` }}
        >
          <div className="debugger-header" onMouseDown={handleMouseDown}>
            <h2>Console Output
              <br/>
              <small>{appConfig.device.toLocaleUpperCase()} {getDeviceIcon()} </small>
            </h2>
            <div className="debugger-header-info">

                <button className="filter" style={{maxWidth: "150px"}}>
                    <Input prefix={<SearchOutlined />} allowClear className="app-input" onChange={(v)=>{setFilter(v.target.value);}}/>
                </button>

                <button className="screen-size-info">
                    <FundProjectionScreenOutlined className="screen-resolution-icon"/>
                    { window.innerWidth }x{window.innerHeight}
                </button>

                <button className="go-bottom-button" onClick={scrollToBottom} title='Scroll to bottom'>
                    <VerticalAlignBottomOutlined className="go-bottom" />
                </button>

                <button className="go-bottom-button" onClick={()=>{changeScreenMode('full-col')}} title='Full col screen'>
                  <BorderOuterOutlined />
                </button>

                <button className="go-bottom-button" onClick={()=>{changeScreenMode('med-col')}} title='Med col screen'>
                  <BorderHorizontalOutlined />
                </button>

                <button className="screen-size-info" onClick={()=>{changeScreenMode('full')}} title='Full screen mode'>
                  <BorderTopOutlined />
                </button>

                <button className="screen-size-info" onClick={()=>{changeScreenMode('med')}} title='Medium screen mode'>
                  <BorderVerticleOutlined />
                </button>

                <button className="screen-size-info" onClick={()=>{changeScreenMode('min')}} title='Min screen mode'>
                  <BorderBottomOutlined />
                </button>

                <button className="show-booking-context-info-button" onClick={showBookingContextInfo} title='Show Booking Context Data'>
                    <AlignLeftOutlined className="show-booking-context-info-icon"/>
                </button>

                <button className="show-booking-context-info-button" onClick={showCartContextInfo} title='Show Cart Context Data'>
                    <ShoppingCartOutlined className="show-booking-context-info-icon"/>
                </button>

                <button className="clear-console-button" onClick={clearConsole} title='Clear console log'>
                    <ClearOutlined className="clear-console-icon"/>
                </button>

                <button className="close-button" onClick={toogleVisible} title='Close console'>
                    <CloseOutlined />
                </button>
            </div>
          </div>
          <div className="welcome-message">
            <p>
              Debugger Beds2B PRO<sup>®</sup> [Versión 1.0.0]
              <br />
              <small>Beds2B<sup>®</sup>. All rights reserved 2023-2024.</small>
            </p>
          </div>
          <ul ref={logContainerRef} className="log-container">
            {consoleOutput
            .filter((log) => !filter || log.message.includes(filter) || log.type.includes(filter) || log.jsonInfo.includes(filter))
            .map((output, index) => (
                <li key={index} className={output.type}>  
                  <div className="log-line">
                    <div className="icon-container">
                      {
                        output.type === "error" || output.jsonInfo !== '' ? 
                        <CopyOutlined className="copy-icon" onClick={()=>{copyToClipBoard(output)}}/> 
                        :
                        <div></div>
                      }
                    </div>
                    <span>
                      {output.type} - {moment(output.datetime).format("YYYY-MM-DD HH:mm:ss")}: {output.message.replace('[object Object]','')} 
                      
                      {output.jsonInfo}

                    </span>
                  </div>
                </li>
            ))}
          </ul>
        </div>
      ) : (
        <FloatButton
          className="app-debugger-button"
          onClick={toogleVisible}
          tooltip={<div>Show console</div>}
          badge={{ count: messageCounter, color: "blue" }}
          icon={<BugOutlined />}
        />
      )}
    </>
  );
};

// Implementación de useForceUpdate
const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const forceUpdate = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return forceUpdate;
};

export default AppDebuggerComponent;
