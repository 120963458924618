import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Contact'

export const ContactEP : EndPointConfig[] =[
        
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("SendContactEmail"),   

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("SendGroupContactEmail"),  
         
]

