import { Button, Col, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import BookingData, { BookingLine, BookingPax, CancellationPolicy, Extra, PaxTypeEnum, PaymentTypeEnum } from '../../../models/BookingData';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';


import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GetShowMoreIcon } from '../../../utils/icons';
import { CancellationPolicies, Occupation } from '../../../components';
import { cancellBooking, fetchBookingData, sendEmailToCustomer } from '../../../api/repositoryEP';
import { CloseCircleOutlined, InfoCircleOutlined, LoadingOutlined, MailOutlined, PrinterOutlined, RollbackOutlined } from '@ant-design/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';

import './CustomerVoucherPageV1.scss'
import { useNavigate } from 'react-router-dom';

const CustomerVoucherPage = () : JSX.Element => {

    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const [bookingData, setBookingData] = useState<BookingData>();

    
    const [visible, setVisible] = useState<boolean>(false);

    const toogleVisibility = () => {
        setVisible(!visible);
    }

    useEffect(()=>{
        //Get booking data
        fetchBookingData('123').then((response) => {
            if (response && response.data){
                setBookingData(response.data);
            }
        });
    },[])


    const center = {
        lat: bookingData?.hotelInfo.latitude ?? 0,
        lng: bookingData?.hotelInfo.longitude ?? 0
    };

    const containerStyle = {
        width: '100%',
        height: '206.359px'
    };

    const { isLoaded } = useJsApiLoader({
                            id: 'google-map-script',
                            googleMapsApiKey: "AIzaSyC34CpfBPB0eXXmONTzlreC2nt7atGRir0"
                        })
    
    const [map, setMap] = useState<google.maps.Map | null>(null)
    
    const onLoad = useCallback(function callback(map: google.maps.Map) {

        map.setZoom(14);

        const markerPosition = { lat: bookingData?.hotelInfo.latitude ?? 0, lng: bookingData?.hotelInfo.longitude ?? 0 };

        const marker = new google.maps.Marker({
            position: markerPosition,
            map: map,
            title: bookingData?.hotelInfo.name, 
          });

        setMap(map)
    }, [bookingData])
    
    const onUnmount = useCallback(function callback(map: any) {
                        setMap(null)
                        }, [])

    
    const navigate = useNavigate();
    const goBack = () : void => {
        navigate(-1);
    }
    
    const [isSendingCopyByEmail, setIsSendingCopyByEmail] = useState<boolean>(false);
    const [isSendingDisabled, setIsSendingDisabled] = useState<boolean>(false);
    const [leftTimeToEnableSendButton, setLeftTimeToEnableSendButton] = useState<number>(0);
    const sendCopyByEmail = () : void => {
        if (bookingData){
            setIsSendingCopyByEmail(true);
            sendEmailToCustomer(bookingData.id).finally(() => {
                setIsSendingCopyByEmail(false);

                //Una vez enviado el correo deshabilitamos el botón durante 30 segundos
                setIsSendingDisabled(true);
                setTimeout(() => {
                    setIsSendingDisabled(false);
                }, 30000);

                //Cada segundo actualizamos el tiempo que queda para habilitar el botón
                setLeftTimeToEnableSendButton(30);
                const interval = setInterval(() => {
                    setLeftTimeToEnableSendButton((prev) => prev - 1);
                }, 1000);

            });
        }
    }

    /**Añadimos un manejador para que cuando se pinche sobre un elemento con la clase resume-link se haga scrollo hasta el elemento con el atributo tag que contenga el nombre del id que tiene resume-link */
    useEffect(() => {
        const resumeLinks = document.querySelectorAll(".resume-link");
      
        resumeLinks.forEach((resumeLink: any) => {
            const handleClick = (event: MouseEvent) => {
            event.preventDefault();
            const tag = (event.currentTarget as HTMLAnchorElement)?.getAttribute(
              "id"
            );
            const element = document.querySelector(`[data-tag='${tag}']`);
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }
          };
      
          resumeLink.addEventListener("click", handleClick);
      
          return () => {
            resumeLink.removeEventListener("click", handleClick);
          };
        });
      }, []);


    return (
        <div className="customer-voucher-page">
            antiguo voucher
        </div>
        )
}

export default CustomerVoucherPage;