import React, { useRef, useEffect } from 'react';
import './GoogleMapsComponent.scss';
import { useGoogleMapsContext } from '../../../Contexts/GoogleMapsContext';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';


interface GoogleMapsComponentProps {
  hotelList: any[],
  onSelectedHotel: (hotel: any) => void,
  apiKey: string,
}

const GoogleMapsComponent = (props: GoogleMapsComponentProps): JSX.Element => {
  const googleMapsContext = useGoogleMapsContext();
  const appContext = useAppConfig();
  const { t } = useTranslation();

  
  const mapContainer = useRef(null);

  const selectHotel = (hotelCode: string) => {
    const selectedHotel = props.hotelList.find(hotel => hotel.code === hotelCode);
    if (selectedHotel) {
      props.onSelectedHotel({name: selectedHotel.name, code: selectedHotel.code});
    }

  };

  const initMap = () => {
    let openInfoWindow: google.maps.InfoWindow | null = null; // Variable para almacenar el InfoWindow abierto


    if (window.google) {
      const mapOptions = {
        center: { lat: props.hotelList[0]?.latitude ?? 40.7128, lng: props.hotelList[0]?.longitude ?? -74.0060 },
        zoom: 10,
        disableDefaultUI: false,
        zoomControl: true,
        streetViewControl: true,
        mapTypeControl: false,
      };
  
      const map = new window.google.maps.Map(mapContainer.current!, mapOptions);
  
      
  
      props.hotelList.forEach((hotel: any) => {
        const marker = new window.google.maps.Marker({
          position: { lat: hotel.latitude, lng: hotel.longitude },
          map: map,
          title: hotel.name,
        });
  
        const infoContent = `
        <div style='display: flex; gap: 10px;'>
            ${
              hotel.mainImageUrl ? `<div style="background-image:url('${hotel.mainImageUrl}'); width: 120px; height: 65px; background-size: cover; background-repeat: no-repeat; background-position: center;">
                                  </div>` : ``
            }
            <div>
              <h4 style="margin: 0;">${hotel.name}</h4>
              <small style="margin-bottom: 15px; display: block;">${hotel.city}, ${hotel.countryName}</small>
              <button id="selectButton-${hotel.code}" class="app-button">${t('components.google-components.google-maps.select')}</button>
              <button id="goGoogleMapsButton-${hotel.code}"class="app-button" onclick="window.open('https://www.google.com/maps/search/?api=1&query=${hotel.latitude},${hotel.longitude}', '_blank');">${t('components.google-components.google-maps.see-on-google-maps')}</button>
            </div>
          </div>
      `;
  
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoContent,
        });

        const handleClick = () => {
          infoWindow.close();
          selectHotel(hotel.code)
        };
  
        marker.addListener('click', () => {
          if (openInfoWindow) {
            openInfoWindow.close();
          }

          infoWindow.open(map, marker);
          openInfoWindow = infoWindow;
          
          // Agregar eventos de clic a los botones después de abrir el infoWindow
          setTimeout(() => {
            // Agregar eventos de clic a los botones después de abrir el infoWindow
            const infoWindowContent = document.querySelector('.gm-style-iw') as HTMLElement;
        
            if (infoWindowContent) {
              const selectButton = infoWindowContent.querySelector(`#selectButton-${hotel.code}`);
        
              if (selectButton) {
                selectButton.removeEventListener('click', handleClick);
                setTimeout(() => {
                  selectButton.addEventListener('click', handleClick);
                  
                }, 50);
              }
            }
          }, 50); 
        });
      });
    }
  };
  
  

  useEffect(() => {
    const googleMapsApiKey = appContext.config.scripts.find(script => script.key === 'google-maps-api-key')?.value;
    googleMapsContext.initMapScript(googleMapsApiKey).then(() => initMap());

    console.log("GoogleMapsComponent.tsx ->", googleMapsApiKey, props);
  }, []);


  return <div ref={mapContainer} className="google-maps-component" style={{ height: '300px', width: '100%' }} />;
};

export default GoogleMapsComponent;
