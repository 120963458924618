import { Alert } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './GoogleMapsAutoCompleteComponent.scss';
import { useGoogleMapsContext } from '../../../Contexts/GoogleMapsContext';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import internal from 'stream';



interface AddressData {
  city: string;
  state: string;
  zip: string;
  country: string;
  street: string;
  streetNumber : string;
  formattedAddress: string;
}

const extractAddress = (place: any): AddressData | null => {
    const address : AddressData = {
        city: "",
        state: "",
        zip: "",
        country: "",
        street: "",
        formattedAddress: "",
        streetNumber: "",
    };

    if (!Array.isArray(place.address_components)){
        return null;
    }

    address.formattedAddress = place.formatted_address;

    place.address_components.forEach((component: any) => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("locality")){
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")){
            address.state = value;
        }

        if (types.includes("postal_code")){
            address.zip = value;
        }

        if (types.includes("country")){
            address.country = value;
        }

        if (types.includes("route")){
            address.street = value;
        }
        if (types.includes("street_number")){
          address.streetNumber = value;
      }
    })

    console.log("Selected address: ", address);
    

    return address;
};


interface GoogleMapsAutoCompleteComponentProps {
  onSetData: (googleData: any) => void;
  showSelectedInfo: boolean;
  isRequiredField: boolean;
  onClearInput: () => void;
}

const GoogleMapsAutoCompleteComponent: React.FC<GoogleMapsAutoCompleteComponentProps> = (props) => {
  const googleMapsContext = useGoogleMapsContext();
  const appContext = useAppConfig();

  const searchInput = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const [address, setAddress] = useState<AddressData>({
    city: '',
    state: '',
    zip: '',
    country: '',
    street: '',
    formattedAddress: '',
    streetNumber: '',
  });

  const [error, setError] = useState<string>('');


  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();

    props.onSetData(place);
    const extractedAddress = extractAddress(place);

    if (extractedAddress) {
      setError('');
      setAddress(extractedAddress);
    } else {
      setError('invalid_address');
    }
  };

  const initAutocomplete = () => {
    if (!searchInput.current) {
      return;
    }

    const autocomplete = (window as any).google
      ? new (window as any).google.maps.places.Autocomplete(searchInput.current)
      : new (window as any).google.maps.places.Autocomplete(searchInput.current);

    autocomplete.setFields(['address_component', 'geometry', 'place_id', 'name', 'formatted_address', 'plus_code']);
    autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
  };

  useEffect(() => {
    const googleMapsApiKey = appContext.config.scripts.find(script => script.key === 'google-maps-api-key')?.value;
    googleMapsContext.initMapScript(googleMapsApiKey).then(() => initAutocomplete());
  }, []);

  return (
    <div className="google-maps-autocomplete">
      <div className="search">
                    <input onChange={(input : any)=>{
                            props.onSetData(undefined);
                            if (!input || !input.target || input.target.value.length === 0){
                                props.onClearInput();
                            }
                        }} 
                        className='app-input css-dev-only-do-not-override-byeoj0' 
                        ref={searchInput} 
                        placeholder={t("components.google.geocode.place-holder")!} 
                        style={{width: "96%", border: "solid 1px #d9d9d9", height: "29px", padding: "0 0px 0 15px"}}
                    />

                </div>

                {
                    props.showSelectedInfo
                    ? 
                        <div className="address">
                            <p>Country: {address.country}</p>
                            <p>State: {address.state}</p>
                            <p>City: {address.city}</p>
                            <p>Zip: {address.zip}</p>
                            <p>Address: {address.street}</p>
                            <p>Formatted Address: {address.formattedAddress}</p>
                            <p>Formatted Street Number: {address.streetNumber}</p>
                        </div>
                    :
                        <></>
                }
                {
                    error && error.length > 0 ? <Alert type='warning' showIcon message={t('googleMaps:invalid-address')} style={{marginTop: "10px"}}/> : <></>
                }
    </div>
  );
};

export default GoogleMapsAutoCompleteComponent;
