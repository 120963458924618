import { Drawer, FloatButton, Layout } from "antd";
import {
  LanguageSelector,
  PrivateFooter,
  PrivateHeader,
  PrivateMenu,
} from "../../components/common";
import { useEffect, useState } from "react";

import "./PrivatePageWrapper.scss";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useAppConfig } from "../../Contexts/AppConfigContext";
import { useCloseSession } from "../../api/repositoryEP";
import { useSessionData } from "../../Contexts/SessionDataContext";
import { GetFormattedUrl } from "../../utils/urls";
import { hexToRgb } from "../../utils/utils";

interface PrivatePageWrapperProps {
  children: JSX.Element;
}

const PrivatePageWrapper = (props: PrivatePageWrapperProps): JSX.Element => {
  const appConfig = useAppConfig();
  const sessionData = useSessionData();

  const closeSession = useCloseSession;

  const [style, setStyle] = useState<string>("");

  useEffect(() => {
    const lightTheme = `
                --engine-widget-input-background: white;
                --engine-widget-input-color: #888888;
                --engine-widget-input-color-icon: #888888;
            
                --engine-widget-button-background:${appConfig.config.mainColor};
                --engine-widget-button-color: white;
                --engine-widget-button-color-icon: white;
            
                --engine-widget-autocomplete-text-color: #AAAAAA;
                --engine-widget-autocomplete-text-color-secondary: #888888;
            
                --engine-widget-multiroom-background: white;
                --engine-widget-multiroom-text-color: #AAAAAA;
                --engine-widget-multiroom-card-background: #fcfcfc;
                --engine-widget-multiroom-card-box-shadow: #cecccc;
                --engine-widget-multiroom-card-text: #AAAAAA;

                --engine-widget-steps-background: white;
                --engine-widget-steps-text-color: #424C53;
                --engine-widget-steps-inactive-background: #ECECEC;
                --engine-widget-steps-inactive-border-color: #ECECEC;

                --engine-widget-hotel-list-background: white;
                --engine-widget-hotel-list-text-color: #424C53;
                --engine-widget-hotel-list-selected-background: #959799;
                --engine-widget-hotel-list-selected-color: white;

                --engine-calendar-background: white;
                --engine-calendar-disabled-text-color: #cfcfcf;
                --engine-calendar-text-color: #424C53;
                --engine-calendar-price-text-color: #249B64;
                --engine-calendar-selected-day-background: #d4d4d4;

                --calendar-primary-color: var(--engine-calendar-text-color);
            `;

    const darkTheme = `
                --engine-widget-input-background: #424C53;
                --engine-widget-input-color: white;
                --engine-widget-input-color-icon: #AAAAAA;
            
                --engine-widget-button-background: ${appConfig.config.mainColor};
                --engine-widget-button-color: white;
                --engine-widget-button-color-icon: white;
            
                --engine-widget-autocomplete-text-color: #FFFFFF;
                --engine-widget-autocomplete-text-color-secondary: #AAAAAA;
            
                --engine-widget-multiroom-background: $dark-gray;
                --engine-widget-multiroom-text-color: white;
                --engine-widget-multiroom-card-background: #424C53;
                --engine-widget-multiroom-card-box-shadow: $dark-gray;
                --engine-widget-multiroom-card-text: white
            
                --engine-widget-steps-background: #424C53;
                --engine-widget-steps-text-color: white;
                --engine-widget-steps-inactive-background: #424C53;
                --engine-widget-steps-inactive-border-color: #343940;

                --engine-widget-hotel-list-background: #424C53;
                --engine-widget-hotel-list-text-color: white;
                --engine-widget-hotel-list-selected-background: #959799;
                --engine-widget-hotel-list-selected-color: white;

                --engine-calendar-background: #424C53;
                --engine-calendar-disabled-text-color: #858585;
                --engine-calendar-text-color: white;
                --engine-calendar-price-text-color: #249B64;
                --engine-calendar-selected-day-background: #343940;

                --calendar-primary-color: var(--engine-calendar-text-color);
                `;

    setStyle(`
              :root {
                --rgba-05: rgba(${hexToRgb(
      appConfig.config.secondaryColor
    )}, 0.1);
                --primary-app-color: ${appConfig.config.mainColor};
                --secondary-app-color: ${appConfig.config.secondaryColor};
                --primary-color: ${appConfig.config.widget.mainColor};
                --secondary-color: ${appConfig.config.widget.secondaryColor};
                --highlight-color: ${appConfig.config.widget.mainColor};
                --calendar-highlight-color: ${appConfig.config.widget.mainColor
      };
                .solbooking-calendar{
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                }

                ${appConfig.config.widget.searchTheme === "light"
        ? lightTheme
        : darkTheme
      }
                
              }
            `);
  }, [appConfig]);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    switch (appConfig.device) {
      case "mobile":
        setCollapsed(true);
        break;
      case "tablet":
        setCollapsed(true);
        break;
      default:
        setCollapsed(false);
        break;
    }
  }, [appConfig.device])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const doLogout = (): void => {
    closeSession(sessionData.userData.id)
      .then()
      .finally(() => {
        window.location.href = GetFormattedUrl("");
      });
  };

  return (
    <Layout className="private-page-wrapper">
      <style>{style}</style>
      <PrivateHeader toogleCollapse={toggleCollapsed} doLogout={doLogout} />
      <Layout className="private-content">
        {appConfig.device === "mobile" ? (
          <Drawer
            placement="left"
            open={!collapsed}
            onClose={toggleCollapsed}
            width={256}
          >
            <PrivateMenu onSelectItem={toggleCollapsed} />
          </Drawer>
        ) : (
          <Sider
            id="sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={60}
            width={250}
            className="site-layout"
          >
            <PrivateMenu onSelectItem={(selectedItem: string) => { }} />
          </Sider>
        )}

        <Content className="private-children content-page" id="content-page">
          {props.children}

          <FloatButton.BackTop
            className="private-scroll-top"
            visibilityHeight={0}
            onClick={() => {
              const scrollTopElement = document.getElementById("content-page")!;

              console.log("scrollTopElement", scrollTopElement);

              // Desplaza hacia arriba
              scrollTopElement.scrollTop = 0;
            }}
          />
        </Content>
      </Layout>
      <PrivateFooter />
    </Layout>
  );
};

export default PrivatePageWrapper;
