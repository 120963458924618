import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UsersAdd, UsersEdit, UsersFilter, UsersList } from "../../../components";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { Drawer, Skeleton } from "antd";
import UsersInfo, { SmallUsersDataList, UserFilterData } from "../../../models/UsersInfo";
import { deleteUser, fetchUserInfo, fetchUserList, pushNewUser, updateUser } from "../../../api/repositoryEP";
import ApiResponse from "../../../models/api/ApiResponse";
import './UsersPage.scss';
import { useSessionData } from "../../../Contexts/SessionDataContext";
import ProfileData from "../../../models/ProfileData";

const UsersPage = () : JSX.Element => {
    const { t } = useTranslation();
    const userSessionData = useSessionData();
    const appConfig = useAppConfig();

    const [showAddUser, setShowAddUser] = useState<boolean>(false);
    const [showEditUser, setShowEditUser] = useState<boolean>(false);


    const [userList, setUserList] = useState<SmallUsersDataList>({totalRecords: 0, elements: []});
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

   

    // si los dos son falsos, hacemos el search
    useEffect(() => { 
        if(!showAddUser && !showEditUser){
            onSearch(6,1); 
        }
      
    },[showAddUser, showEditUser]);

    const onCloseAddUser = () : void => {
        setShowAddUser(false);
    }

    const onShowAddUser = () : void => {
        setShowAddUser(true);
    }

    const onCloseEditUser = () : void => {
        setShowEditUser(false);
    }

    const [lastFilterData, setLastFilterData] = useState<UserFilterData>({
        name: "",
        username: "",
        custom: [],
        email: "",
        phone: "",
        numRecords: 6,
        page: 1
    });

    const getUsers = ( filterData : UserFilterData) : void => {
        setIsLoadingData(true);
        filterData.numRecords = lastFilterData.numRecords;
        filterData.page = lastFilterData.page;

        const customFields = Object.entries(filterData)
        .filter(([key]) => key.startsWith("custom."))
        .map(([key, value]) => ({
            key: key.replace("custom.", ""),
            value
        }));

        const transformedData : any  = {
            ...filterData,
            custom: customFields
            };

        // Eliminar las propiedades que empiezan por "custom."
        Object.keys(transformedData).forEach(key => {
            if (key.startsWith("custom.")) {
            delete transformedData[key];
            }
        });
      
        fetchUserList(transformedData,userSessionData.userData.typeAccount,userSessionData.userData?.idOrganization).then((response) => {    
            if (response?.type == 1){
                setUserList(response?.data as SmallUsersDataList);
            }
        }).finally(()=>{
            setIsLoadingData(false);
        })
    }

    const [addCounter, setAddCounter] = useState<number>(0);
    const onAddUser = (userInfo: UsersInfo) : void => {
        pushNewUser(userInfo).then((response: ApiResponse | null) => {
            if (response?.type === 1 ) {
                setShowAddUser(false);
                setAddCounter(addCounter + 1);
            }
        });
    }

    const onFilter = (filter: UserFilterData) : void => {
        setLastFilterData(filter);
        getUsers(filter);
    }

    const onSearch = (rows: number, page: number) : void => {    
        let newFilterdata = lastFilterData;
        newFilterdata.numRecords = rows;
        newFilterdata.page = page;
        onFilter(newFilterdata);
    }

    const [editUser, setEditUser] = useState<UsersInfo | null>(null);
    const getUser = (id: string) : void => {
        setShowEditUser(false);
        fetchUserInfo(id).then((response: ApiResponse | null) => {
            if (response?.type === 1) {
                setEditUser(response.data);
                setShowEditUser(true);
            }
        });
    }

    const onSaveChanges = (userInfo: UsersInfo) : void => {
        updateUser(userInfo).then((response: ApiResponse | null) => {
            if (response?.type === 1) {
                setShowEditUser(false);
                getUsers(lastFilterData);
            }
        });
    }

    const onDeleteUser = (id: string) : void => {
        deleteUser(id).then((response: ApiResponse | null) => {
            if (response?.type === 1) {            
                getUsers(lastFilterData);
            }
        })
    }

    return(
        <div className="users-page">
            <h2 className="title">{t('pages.users-page.main-title')}</h2>
            <h3 className="sub-title">{t('pages.users-page.subtitle')}</h3>

            <Drawer width={appConfig.device === "mobile" ? "100%" : (appConfig.device === "tablet" ? "50%" : "30%")} title={t('pages.users-page.add-new-user')} placement="right" onClose={onCloseAddUser} open={showAddUser}>
                <UsersAdd onAddUser={onAddUser} counter={addCounter}/>
            </Drawer>

            <Drawer width={appConfig.device === "mobile" ? "100%" : (appConfig.device === "tablet" ? "50%" : "30%")} title={t('pages.users-page.edit-user')} placement="right" onClose={onCloseEditUser} open={showEditUser}>
                <UsersEdit editingUser={editUser} onSaveChanges={onSaveChanges}/>
            </Drawer>

            <div className="users-filter">
                <UsersFilter onFilter={onFilter} onShowAddUser={onShowAddUser} customFields={appConfig.config.customRegisterFields}/>
            </div>

            <div className="users-list">
                   <Skeleton active={isLoadingData} loading={isLoadingData} >  
                      <UsersList onGetUserToEdit={getUser} onDeleteUser={onDeleteUser} userList={userList} search={onSearch}/> 
                      </Skeleton>
                  
            </div>
        </div>
    )
}

export default UsersPage;