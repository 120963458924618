  // Enums
  
  /**
   * d => D Habitación/día UNID * DIA * PRECIO
   * p => P Pax/día PAX * DÍA * PRECIO
   * u => U Paquete/habitación UNID * PRECIO
   * x => X Paquete/pax PAX * PRECIO
   * */
  export enum ApplicationPriceTypeEnum {
    d = 'D',
    p = 'P',
    u = 'U',
    x = 'X',
  }
  
  export enum PaxTypeEnum {
    adult = 'adult',
    teenager = 'teenager',
    child = 'child',
    infant = 'infant',
  }
  
  export enum OfferSupplementTypeEnum {
    offer = 'offer',
    supplement = 'supplement',
  }
  
  export enum PaymentTypeEnum {
    fixed = 'fixed',
    percent = 'percent',
  }

  // Interfaces for other tables
  
 export interface BookingPax {
    id: number;
    //0 Adultos, 1 teneger, 2 niños, 3 bebes
    paxType: PaxTypeEnum | number;
    age: number;
    name: string;
    surname: string;
    secondSurname?: string;
  }
  
  export default interface BookingData {
    bookingLines: BookingLine[]; // Collection of BookingLine
    currencyCode: string;
    extras: Extra[]; // Collection of Extra
    hotelInfo: HotelInfo; // Reference to HotelInfo
    id: number;
    isCancelled: boolean;
    isNetPrice: boolean;
    lastUpdateDate: Date;
    observations: string;
    ownerData: OwnerData; // Reference to OwnerData
    pmsLocator: string;
    proLocator: string;
    recordDate: Date;
    reward: number;
    status: number;
    taxes: Tax[]; // Collection of Tax
  }

 export interface CancellationPolicy{
    from: Date | null;
    to: Date | null;
    penalty: number;
    penaltyType: PaymentTypeEnum;
  }

 export interface BookingLine {
    id: number;
    mainImage: string;
    roomName: string;
    roomTypeId: number;
    roomCode: string;
    regimeName: string;
    regimeTypeId: number;
    regimeTypeCode: string;
    checkIn: Date;
    checkOut: Date;
    pvp: number;
    net: number;
    commission: number;
    totalAmount: number;
    totalCommissionAmount: number;
    totalAmountBeforeTaxes: number;
    totalCommissionBeforeTaxes: number;
    roomKey: string;
    paxes: BookingPax[]; // Collection of BookingPax
    bookingLineSummary: BookingLineSummary[]; // Collection of BookingLineSummary
    taxes: Tax[]; // Collection of Tax
    cancellationPolicies: CancellationPolicy[]; // Collection of CancellationPolicy
  }
  
 export interface BookingPricing {
    id: number;
    currencyCode: string;
    pvp: number;
    net: number;
    commission: number;
    exchangeValue: number;
    exchangeValueDate: Date;
    pvpExchange: number;
    netExchange: number;
    commissionExchange: number;
    amountType: ApplicationPriceTypeEnum;
  }
  
 export interface BookingLineSummary {
    id: number;
    from: Date;
    to: Date;
    applicationPriceType: ApplicationPriceTypeEnum;
    applicationRegimePriceType: ApplicationPriceTypeEnum;
    price: number;
    priceBeforeTaxes: number;
    agencyCommission: number;
    agencyCommissionBeforeTaxes: number;
    regimePrice: number;
    regimePriceBeforeTaxes: number;
  }
  
 export interface Extra {
    id: number;
    extraId: number;
    extraCode: string;
    description: string;
    applicationDateFrom: Date;
    applicationDateTo: Date;
    quantity: number;
    price: number;
    priceBeforeTax: number;
    applicationPriceType: ApplicationPriceTypeEnum;
    image: string;
  }
  
 export interface OwnerData {
    id: number;
    name: string;
    surname: string;
    secondSurname?: string;
    phone?: string;
    email: string;
  }
  
 export interface OffersAndSupplement {
    id: number;
    offerAndSupplementId: number;
    offerAndSupplemetCode: string;
    type: OfferSupplementTypeEnum;
    description: string;
    applyFrom: Date;
    applyTo: Date;
    applyOrder: number;
    amount: number;
    amountBeforeTax: number;
  }
  
 export interface Tax {
    id: number;
    percent: number;
  }
  
 export interface HotelInfo{
    id: number;
    code: string;
    name: string;
    address: string;
    phone: string;
    latitude: number,
    longitude: number,
    mainImage: string,
    website: string,
    director: string
  }

 
  
  