import { useTranslation } from "react-i18next";

import './DashboardPage.scss'

import { ImagePath } from "../../../utils/urls";
import { EngineWidget, Statistics } from "../../../components";
import { Col, Row } from "antd";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { useEffect, useState } from "react";
import { HistoryItem } from "@beds2b-group/reusable-components/dist/types";
import { fetchUserHistory } from "../../../api/repositoryEP";
import { useBookingEngineContext } from "../../../Contexts/EngineDataContext";
import { getAvailableHotels, Place, searchCoincidencesForWidget } from "../../../utils/utils";

const DashboardgPage = () : JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();

    const bookingEngineContext = useBookingEngineContext();

    const imagePath = ImagePath();

    const [userHistory, setUserHistory] = useState<Array<HistoryItem>>([]);
    const getUserHistory = () => {
        fetchUserHistory().then((response) => {
            if (response){
                setUserHistory(response.data)
            }else{
                setUserHistory([])
            }
        });
    }

    const [searchCoincidences, setSearchCoincidences] = useState<Array<any>>([]);
    const getSearchCoincidences = (v: string) => {
        if (v.length < 3) return setSearchCoincidences([]);

        var coincidences = searchCoincidencesForWidget(appConfig.config.hotels, v);

        setSearchCoincidences(coincidences);
    }

    const [availableDestinations, setAvailableDestinations] = useState<Array<any>>([]);
    const getAvilableDestinations = () => {
        setAvailableDestinations(getAvailableHotels(appConfig.config.hotels));
    }

    useEffect(()=>{
        getUserHistory();
        getAvilableDestinations();
        bookingEngineContext.clearContext();
    },[])

    return(
        <div className="dashboard-page">
            <div className="booking-widget">
                <h1>
                    {t("pages.dashboard.booking-widget-title")}
                </h1>
                <div>
                    <EngineWidget userHistory={userHistory} onGetCoincidences={getSearchCoincidences} searchCoincidences={searchCoincidences} availableDestinations={availableDestinations}/>
                </div>
            </div>
            <div className="statistics-widgets">
                <h1>
                    {t("pages.dashboard.statistics-widget-title")}
                </h1>

                <div className="image-container" style={{backgroundImage:`url(/${imagePath}/statistics-image-separator.png)`}}>
                    &nbsp;
                </div>
                <div className="statistics-widgets-container">
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2024'}
                                    symbol={"USD"}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={10}                                    
                                    data={[10042, 8065, 7078, 3091, 20054, 14036, 13070, 9085, 120102, 110112, 40127, 180136]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Comisiones obtenidas en 2024'}
                                    symbol={"USD"}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={50}
                                    data={[10042*0.1, 8065*0.1, 7078*0.1, 3091*0.1, 20054*0.1, 14036*0.1, 13070*0.1, 9085*0.1, 120102*0.1, 110112*0.1, 40127*0.1, 180136*0.1]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2023'}
                                    symbol={"USD"}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={32}
                                    data={[0.019999999999, 10042*0.9, 8065*0.9, 7078*0.9, 3091*0.9, 20054*0.9, 14036*0.9, 13070*0.9, 9085*0.9, 120102*0.9, 110112*0.9, 40127*0.9, 180136*0.9]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Comisiones obtenidas en 2023'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={95}
                                    data={[0.0, 10042*0.9*0.1, 8065*0.9*0.1, 7078*0.9*0.1, 3091*0.9*0.1, 20054*0.9*0.1, 14036*0.9*0.1, 13070*0.9*0.1, 9085*0.9*0.1, 120102*0.9*0.1, 110112*0.9*0.1, 40127*0.9*0.1, 180136*0.9*0.1]}
                                    />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default DashboardgPage;