import { Checkbox, Col, Form, Input, Radio, Select } from "antd";
import CustomField, { GenericKeyValuePair, KeyValuePair, TranslatedLabel } from "../models/CustomField";
import { GetLanguageInUrl } from "./urls";

 
export const GetCustomFields = (fields: CustomField[], t: any, userCustomFields : KeyValuePair[] | undefined) : JSX.Element[] => {
    let result : JSX.Element[] =  [];
    let newField : JSX.Element = <></>;

    const actualLanguage = GetLanguageInUrl().replace("/", "");

    //filtrados por idioma
    fields = fields.sort((f1: CustomField, f2: CustomField) => f1.order >= f2.order ? 0 : -1);

    fields.forEach((field: CustomField) => {
        const existingFieldValue = userCustomFields?.find((uf) => uf.key === field.name)?.value;
        switch(field.fieldType){
            case 'input':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={ existingFieldValue || field.defaultValue }
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input className="app-input" placeholder={field.placeHolder}/>
                                </Form.Item>
                            </Col>
                result.push(newField);                    
                break;
            case 'checkbox':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={existingFieldValue} 
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'radio':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={field.defaultValue ||existingFieldValue}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Radio />
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'select':
                console.log("Field", field, field.options);
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={existingFieldValue || field.defaultValue}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Select showSearch={field.searchable} placeholder={field.placeHolder}>
                                        {
                                            field.options.map((option: GenericKeyValuePair) => <Select.Option key={`opt-${option.key}`} value={option.key}>
                                                                                            {option.value}
                                                                                        </Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'text-area':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={existingFieldValue || field.defaultValue}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input.TextArea placeholder={field.placeHolder}/>
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
        }
    })


    return result;
}