import { useTranslation } from "react-i18next";
import './ContactPage.scss'
import { ContactForm } from "../../../components";
import { ContactFormInfo } from "../../../models/ContactFormInfo";
import { useEffect, useState } from "react";
import { sendContactEmail } from "../../../api/repositoryEP";
import { useSessionData } from "../../../Contexts/SessionDataContext";
import ContactPhonesComponent from "../../../components/common/contact/ContactPhonesComponent";
import { ContactPhoneInfo } from "../../../models/AppConfig";
import { useAppConfig } from "../../../Contexts/AppConfigContext"; 

const PrivateContactPage = () : JSX.Element => {
    const { t } = useTranslation();

    const sessionData = useSessionData();
    const appConfig = useAppConfig();

    const [defaultData, setDefaultData] = useState<{name:string, email: string}>({name: '', email: ''});  
    
    const [contactPhones, setContactPhones] = useState<ContactPhoneInfo[]>([]);

    useEffect(()=>{
        if (appConfig?.config?.contactPhones){
            setContactPhones(appConfig.config.contactPhones);
        }    
    },[appConfig])

    const [sendingData, setSendingData] = useState<boolean>(false);
    const [sendStatus, setSendStatus] = useState<'ok' | 'ko' | ''>('');
    const sendEmail = (values: ContactFormInfo) : void => {
        setSendingData(true);
        setSendStatus('');
        sendContactEmail(values).then((r)=>{
                if (r?.type === 1){
                    setSendStatus('ok');
                }else{
                    setSendStatus('ko');
                }
        }).finally(()=>{
            setSendingData(false);
        })
    }


    useEffect(()=>{
        if (sessionData){
            setDefaultData({
                name: sessionData.userData.name + ' ' + sessionData.userData.surname + ' ' + sessionData.userData.secondSurname,
                email: sessionData.userData.email
            });
        }
    },[sessionData])

    return(
        <div className="contact-page">
            <h2 className="title">{t('pages.private-contact-page.main-title')}</h2>
            <h3 className="sub-title">{t('pages.private-contact-page.subtitle')}</h3>
            
            <ContactPhonesComponent contactPhoneList={contactPhones} />

            <ContactForm onFinish={sendEmail} sending={sendingData} defaultValues={defaultData} sendStatus={sendStatus}/>
        </div>
    )
}

export default PrivateContactPage;