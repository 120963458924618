import { useTranslation } from "react-i18next";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { useEffect, useState } from "react";

import './LoginPage.scss'

import defaultBackgroundImage from '../../../assets/images/default-login-background.png'
import { ForgetPassword, LoginForm } from "../../../components";
import { useDoLogin, useRecoverPassword } from "../../../api/repositoryEP";
import ApiResponse from "../../../models/api/ApiResponse";
import UserSessionData from "../../../models/UserSessionData";
import { GetFormattedUrl} from "../../../utils/urls";
import { Modal, notification } from "antd";
import HTMLReactParser from "html-react-parser";

const LoginPage = () : JSX.Element => {
    const appConfig = useAppConfig();

    const doLogin  = useDoLogin;
    const recoverPassword = useRecoverPassword;

    const [backgroundPage, setBackgroundPage] = useState<string>(defaultBackgroundImage);

    const { t } = useTranslation();
 
    const [haveError, setHaveError] = useState<boolean>(false);
    const [doingLogin, setDoingLogin] = useState<boolean>(false);
    const onLogin = (username: string, password: string) : void => {
        setDoingLogin(true);
        doLogin(username, password).then((r: ApiResponse | null)=>{console.log(r)
            if (r && r.type == 1){
                var userSessionData = r.data as UserSessionData;
                if (userSessionData && userSessionData.isLogged){
                    window.sessionStorage.setItem("user-data", JSON.stringify(userSessionData));
                    
                    const url = GetFormattedUrl('private/dashboard');
                    
                    window.location.href = url;
                }
            }else{
                setHaveError(true);
            }
        })
        .finally(()=>{
            setDoingLogin(false);
        });
    }

    const [showForgetPassword,setShowForgetPassword] = useState<boolean>(false);
    const onForgetPassword = () : void => {
        setShowForgetPassword(true);
    }

    const closeForgetPassword = () : void => {
        setShowForgetPassword(false);
    }

    const [sendingForgetPasswordEmail, sendSendingForgetPasswordEmail] = useState<boolean>(false);
    const submitForgetPasswordEmail = (email: string) : void => {
        sendSendingForgetPasswordEmail(true);
        recoverPassword(email).then((r: ApiResponse | null) => {
            if (r && r.type === 1){               
                closeForgetPassword();
            }
        })
        .finally(()=>{
            sendSendingForgetPasswordEmail(false);
        })
    }

    useEffect(()=>{
        if (appConfig && appConfig.config.images && appConfig.config.images.loginBackGround && appConfig.config.images.loginBackGround.length > 0){
            setBackgroundPage(appConfig.config.images.loginBackGround);
        }
    },[])

    return(
        <div className="login-page">
            <div className="login-page-content no-margin" style={{background: `url(${backgroundPage})`, backgroundPosition: "right", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                <Modal className="app-modal" title={t("pages.login-page.forget-password-modal-title")} open={showForgetPassword} footer={null} onCancel={closeForgetPassword}>
                    <ForgetPassword onCancel={closeForgetPassword} onSendForm={submitForgetPasswordEmail} loading={sendingForgetPasswordEmail} />
                </Modal>
                <div className="form-content">
                    <img className="app-logo" src={appConfig.config.images.appLogo} />
                    <span className="welcome-title">
                        {t("pages.login-page.welcome")}
                    </span>
                    <LoginForm onLogin={onLogin} doingLogin={doingLogin} haveError={haveError} onForgetPassword={onForgetPassword}/>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;