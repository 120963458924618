
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd';
import UsersInfo from '../../../models/UsersInfo';
import './UsersAddComponent.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isValidEmail, isValidUsername } from '../../../api/repositoryEP';
import { Language } from '../../../models/Language';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { GetLanguageInUrl } from '../../../utils/urls';
import { GetCustomFields } from '../../../utils/customfields';
import CustomField from '../../../models/CustomField';
import { LoadingOutlined } from '@ant-design/icons';
import { useSessionData } from '../../../Contexts/SessionDataContext';

interface UsersAddComponentProps {
    onAddUser: (userData: UsersInfo) => void
    counter: number;
}

const UsersAddComponent = (props: UsersAddComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const appConfig = useAppConfig();
    const sessionData = useSessionData();

    const [form] = Form.useForm();
    const { onAddUser } = props;

    const [emailValue, setEmailValue] = useState('');
    const [usernameValue, setUsernameValue] = useState('');
    const [customFields, setCustomFields] = useState<JSX.Element[]>([])
    const [wantReceiveEmails, setWantReceiveEmails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChangeSetWantToReceiveEmails = (v: any): void => {
        setWantReceiveEmails(v.target.checked);
    }

    const rolesItems = [
        { value: 'super_admin', label: t("roles.super_admin") },
        { value: 'wa_admin', label: t("roles.wa_admin") },
        { value: 'wa_basic', label: t("roles.wa_basic") }
    ];



    const clearForm = (): void => {
        setWantReceiveEmails(false);
        form.resetFields()
    };

    useEffect(() => {
        clearForm();
    }, [props.counter])

    useEffect(() => {
        if (appConfig && appConfig.config.customRegisterFields.length > 0) {
            loadCustomForms();
        }
    }, [appConfig])


    const onFinish = (): void => {
        setIsLoading(true);
        const data: UsersInfo = {
            id: "",
            name: form.getFieldValue("name"),
            surname: form.getFieldValue("surname"),
            secondSurname: form.getFieldValue("secondSurname") || "",
            email: form.getFieldValue("email"),
            phone: form.getFieldValue("phone") || "",

            role: form.getFieldValue("role"),
            language: form.getFieldValue("language"),
          
            username: form.getFieldValue("uname"),
            password: form.getFieldValue("psw"),
            wantToReceiveEmails: wantReceiveEmails,
            typeAccount: sessionData.userData.typeAccount,
            idOrganization: sessionData.userData.idOrganization,
            customFields: []
        };

        if (appConfig.config.customRegisterFields.length > 0) {
            appConfig.config.customRegisterFields.forEach((field: CustomField) => {
                data.customFields.push({
                    key: field.name,
                    value: form.getFieldValue(field.name)
                })
            })
        }

        onAddUser(data);
        setIsLoading(false);
    }


    const loadCustomForms = (): void => {
        let fields: JSX.Element[] = [];

        if (appConfig.config.customRegisterFields.length > 0) {
            fields.push(<Divider>{t("components.personal-form.title.adicional-field")}</Divider>)

            fields = fields.concat(GetCustomFields(appConfig.config.customRegisterFields, t,undefined));
        }

        setCustomFields(fields);
    }


    return (
        <div className="users-add">
            <Form form={form} layout='vertical' onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.personal-form.name")}
                            name="name"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.personal-form.surname")}
                            name="surname"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.personal-form.second-surname")}
                            name="secondSurname"
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.personal-form.email")}
                            name="email"

                            rules={[
                                {
                                    type: "email",
                                    message: t("forms.validations.invalid-format")!
                                },
                                {
                                    required: true,
                                    message: t("forms.validations.required-field")!
                                },
                                {
                                    validator: async (_, value) => {

                                        // Valida el email usando la función isValidEmail
                                        const response = await isValidEmail(value);
                                        if (response?.type === 1) {
                                            if (!response.data) {
                                                return Promise.reject(t("forms.validations.invalid-email"));
                                            }
                                        } else {
                                            return Promise.reject(t("forms.validations.invalid-email"));
                                        }

                                        // Si todo está bien, se resuelve la promesa
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                value={emailValue}
                                onChange={(e) => setEmailValue(e.target.value)}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.repeat-email")}
                            name="mailRepeat"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {

                                        const email = getFieldValue("email");
                                        console.log(email)
                                        console.log(value)
                                        if (!value) {
                                            return Promise.reject((t("forms.validations.required-field")));
                                        }
                                        if (email !== value) {
                                            return Promise.reject((t("forms.validations.not-match")));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>


                    </Col>
                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.phone")}
                            name="phone"
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Divider> {t("components.personal-form.title.access-data")}</Divider>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t("components.edit-profile.username")}
                            name="uname"
                            initialValue={usernameValue}
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        const regex = /^[a-zA-Z0-9]+$/; // Solo letras y números, sin espacios ni caracteres especiales

                                        // Verifica si el campo está vacío
                                        if (!value) {
                                            return Promise.reject((t("forms.validations.required-field")));
                                        }

                                        // Verifica si tiene caracteres inválidos
                                        if (!regex.test(value)) {
                                            return Promise.reject((t("forms.validations.caracteres-not-valid")));
                                        }



                                        // Valida si el nombre de usuario ya existe
                                        const response = await isValidUsername(value);
                                        if (response?.type === 1) {
                                            if (!response.data) {
                                                return Promise.reject((t("forms.validations.invalid-username")));
                                            }
                                        } else {
                                            return Promise.reject((t("forms.validations.invalid-username")));
                                        }

                                        // Si todo está bien, se resuelve la promesa
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                value={usernameValue}
                                onChange={(e) => setUsernameValue(e.target.value)}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} ></Col>
                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.password")}
                            name="psw"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                            ]}
                        >
                            <Input.Password
                                visibilityToggle
                                onCopy={(e) => {
                                    e.preventDefault();
                                }}
                                onPaste={(e) => {
                                    e.preventDefault();
                                }} className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.repeat-password")}
                            name="repeat-password"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("psw") === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(t("forms.validations.not-match"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                visibilityToggle
                                onCopy={(e) => {
                                    e.preventDefault();
                                }} onPaste={(e) => {
                                    e.preventDefault();
                                }} className="app-input" />
                        </Form.Item>
                    </Col>
                    <Divider></Divider>

                    
                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.language")}
                            name="language"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                            initialValue={GetLanguageInUrl().replace("/", "").toUpperCase()}
                        >
                            <Select>
                                {
                                    appConfig.config.availableLanguages.map((language: Language, index: number) => {
                                        return <Select.Option key={index} value={language.shortCode} disabled={!language.enabled}>{language.description}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} >
                        <Form.Item
                            label={t("components.personal-form.role")}
                            name="role"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select>
                                {rolesItems.map((role, index) => (
                                    <Select.Option key={index} value={role.value}>
                                        {role.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {customFields}
                </Row>
                <div className="checkbox-actions">

                    <Checkbox onChange={onChangeSetWantToReceiveEmails}>
                        {t('components.personal-form.news-on-email-new-user')}
                    </Checkbox>
                </div>
                <div className="app-actions">
                    <br></br>

                    <Button htmlType="submit" className="app-button" disabled={isLoading}>
                        {
                            isLoading ? <LoadingOutlined /> : ''
                        }
                        {t('components.personal-form.actions.finish')}
                    </Button>
                </div>
            </Form>

        </div>
    )
}

export default UsersAddComponent;