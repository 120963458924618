import { Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetLanguageInUrl } from "../../../utils/urls";
import { useAppConfig } from "../../../Contexts/AppConfigContext";

import './PersonalFormComponent.scss';
import { Language } from "../../../models/Language";
import ProfileData from "../../../models/ProfileData";
import { LoadingOutlined } from "@ant-design/icons";
import CustomField, { KeyValuePair, TranslatedLabel } from "../../../models/CustomField";
import { GetCustomFields } from "../../../utils/customfields";
import { isValidEmail, isValidUsername } from "../../../api/repositoryEP";
import { useSessionData } from "../../../Contexts/SessionDataContext";

interface PersonalFormComponentProps {
    onPreviousStep: (step?: number) => void,
    onNextStep: (step?: number) => void,
    onFillProfileData: (data: ProfileData) => void,
    isLoading: boolean
}

const PersonalFormComponent = (props: PersonalFormComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const [form] = useForm();
    const { t } = useTranslation();
    const { onPreviousStep, onNextStep, onFillProfileData, isLoading } = props;

    const [customFields, setCustomFields] = useState<JSX.Element[]>([])
    const [enabledButton, setEnabledButton] = useState<boolean>(false);

    const [acceptTermnsAndConditions, setAcceptTermnsAndConditions] = useState<boolean>(false);
    const [wantReceiveEmails, setWantReceiveEmails] = useState<boolean>(false);

    const onChangeTermnsAndConditions = (v: any): void => {
        setAcceptTermnsAndConditions(v.target.checked);
    }

    const onChangeSetWantToReceiveEmails = (v: any): void => {
        setWantReceiveEmails(v.target.checked);
    }

    const [emailValue, setEmailValue] = useState('');
    const sessionData = useSessionData();
    const [usernameValue, setUsernameValue] = useState('');

    

    const onFinish = (): void => {
            const profileData: ProfileData = {
                email: form.getFieldValue("email"),
                repeatEmail: form.getFieldValue("mailRepeat"),
                language: form.getFieldValue("language"),
                prefix: form.getFieldValue("prefix"),
                name: form.getFieldValue("name"),
                surname: form.getFieldValue("surname"),
                secondSurname: form.getFieldValue("secondSurname"),
                phone: form.getFieldValue("phone"),
                username: form.getFieldValue("username"),
                password: form.getFieldValue("password"),
                termnsAndConditionsReaded: acceptTermnsAndConditions,
                wantToReceiveEmails: wantReceiveEmails,
                customFields: []
            };

            if (appConfig.config.customRegisterFields.length > 0) {
                appConfig.config.customRegisterFields.forEach((field: CustomField) => {
                    profileData.customFields.push({
                        key: field.name,
                        value: form.getFieldValue(field.name)
                    })
                })
            }

            onFillProfileData(profileData);
        
    }

    const loadCustomForms = (): void => {
        let fields: JSX.Element[] = [];

        if (appConfig.config.customRegisterFields.length > 0) {
            fields.push(<Divider>{t("components.personal-form.title.adicional-field")}</Divider>)
            fields = fields.concat(GetCustomFields(appConfig.config.customRegisterFields, t,undefined));
        }

        setCustomFields(fields);
    }

    const verifyFields = (): void => {
        const hasErrors = form.getFieldsError().some((field) => {
            const fieldName: string = field.name[0].toString();
            let hasValue: boolean = form.getFieldValue(fieldName) !== undefined && form.getFieldValue(fieldName)?.length > 0;
            let hasError: boolean = field.errors.length > 0 || !hasValue;


            return (fieldName === "secondSurname" || appConfig.config.customRegisterFields.some(cf => cf.name == fieldName && !cf.required)) ? false : hasError;
        });

        setEnabledButton(!hasErrors && acceptTermnsAndConditions);
    }

    useEffect(() => {
        verifyFields();
    }, [acceptTermnsAndConditions])

    useEffect(() => {
        if (appConfig && appConfig.config.customRegisterFields.length > 0) {
            loadCustomForms();
        }
    }, [appConfig])

    return (
        <div className="personal-form-component">
            <Form form={form} onFinish={onFinish} layout="vertical" onFieldsChange={verifyFields}>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.name-prefix")}
                            name="prefix"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select>
                                {
                                    appConfig.config.prefixNames.map((prefix: string, index: number) => {
                                        return <Select.Option key={index} value={prefix}>{prefix}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.name")}
                            name="name"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.surname")}
                            name="surname"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.second-surname")}
                            name="secondSurname"
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>



                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.email")}
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    message: t("forms.validations.invalid-format")!,
                                },
                                {
                                    validator: async (_, value) => {
                                        // Verifica si el campo está vacío
                                        if (!value) {
                                            return Promise.reject(t("forms.validations.required-field"));
                                        }

                                        // Valida el email usando la función isValidEmail
                                        const response = await isValidEmail(value);
                                        if (response?.type === 1) {
                                            if (!response.data) {
                                                return Promise.reject(t("forms.validations.invalid-email"));
                                            }
                                        } else {
                                            return Promise.reject(t("forms.validations.invalid-email"));
                                        }

                                        // Si todo está bien, se resuelve la promesa
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                value={emailValue}
                                onChange={(e) => setEmailValue(e.target.value)}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>

                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.repeat-email")}
                            name="mailRepeat"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       
                                        const email = getFieldValue("email"); 

                                        if (!value) {
                                            return Promise.reject((t("forms.validations.required-field")));
                                        }
                                        if (email !== value) {
                                            return Promise.reject((t("forms.validations.not-match")));
                                        }
                                        return Promise.resolve(); 
                                    },
                                }),
                            ]}
                        >
                            <Input
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>


                    </Col>
                    <Divider> {t("components.personal-form.title.access-data")}</Divider>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.edit-profile.username")}
                            name="username"
                            initialValue={usernameValue}
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        const regex = /^[a-zA-Z0-9]+$/; // Solo letras y números, sin espacios ni caracteres especiales

                                        // Verifica si el campo está vacío
                                        if (!value) {
                                            return Promise.reject((t("forms.validations.required-field")));
                                        }

                                        // Verifica si tiene caracteres inválidos
                                        if (!regex.test(value)) {
                                            return Promise.reject((t("forms.validations.caracteres-not-valid")));
                                        }

                                        if (value === sessionData?.userData?.username) {
                                            return Promise.resolve();
                                        }

                                        // Valida si el nombre de usuario ya existe
                                        const response = await isValidUsername(value);
                                        if (response?.type === 1) {
                                            if (!response.data) {
                                                return Promise.reject((t("forms.validations.invalid-username")));
                                            }
                                        } else {
                                            return Promise.reject((t("forms.validations.invalid-username")));
                                        }

                                        // Si todo está bien, se resuelve la promesa
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                value={usernameValue}
                                onChange={(e) => setUsernameValue(e.target.value)}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                className="app-input"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}></Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.password")}
                            name="password"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                            ]}
                        >
                            <Input.Password
                                visibilityToggle
                                onCopy={(e) => {
                                    e.preventDefault();
                                }}
                                onPaste={(e) => {
                                    e.preventDefault();
                                }} className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.repeat-password")}
                            name="repeat-password"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(t("forms.validations.not-match"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                visibilityToggle
                                onCopy={(e) => {
                                    e.preventDefault();
                                }} onPaste={(e) => {
                                    e.preventDefault();
                                }} className="app-input" />
                        </Form.Item>
                    </Col>


                    <Divider></Divider>


                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.phone")}
                            name="phone"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.language")}
                            name="language"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                            initialValue={GetLanguageInUrl().replace("/", "").toUpperCase()}
                        >
                            <Select>
                                {
                                    appConfig.config.availableLanguages.map((language: Language, index: number) => {
                                        return <Select.Option key={index} value={language.shortCode} disabled={!language.enabled}>{language.description}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    {customFields}
                </Row>
                <div className="checkbox-actions">
                    <Checkbox onChange={onChangeTermnsAndConditions}>
                        {HTMLReactParser(t('components.personal-form.termns-and-conditions', [`${appConfig.config.siteName}/${GetLanguageInUrl()}/termns-and-conditions`, "app-link"]))}
                    </Checkbox>
                    <Checkbox onChange={onChangeSetWantToReceiveEmails}>
                        {t('components.personal-form.news-on-email')}
                    </Checkbox>
                </div>
                <div className="app-actions">
                    <Button onClick={() => { onPreviousStep(); }} className="app-white-button">
                        {t('components.personal-form.actions.prev')}
                    </Button>

                    <Button htmlType="submit" className="app-button" disabled={isLoading || !enabledButton}>
                        {
                            isLoading ? <LoadingOutlined /> : ''
                        }
                        {t('components.personal-form.actions.finish')}
                    </Button>
                </div>
            </Form>
            <br />
            <br />
            <br />
        </div >
    )
}

export default PersonalFormComponent;