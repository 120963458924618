import { Button } from "antd";

import './RefreshCache.scss';

interface RefreshCacheComponentProps{
    setShowRefreshCache: (show: boolean) => void
}

const RefreshCacheComponent = (props: RefreshCacheComponentProps) : JSX.Element => {

    const hideRefreshCache = () => {
        props.setShowRefreshCache(false);
    }

    const goRefreshUrl = () => {
        sessionStorage.clear();
        let timestamp = new Date().getTime();
        let newUrl = window.location.href.split("?")[0] + "?t=" + timestamp;
        window.location.href = newUrl;
    }

    return(
        <div className="refresh-cache">
            <div className="container">
                <p>
                    ¿Quieres recargar la caché de la página y forzar la recarga?
                    <small>
                        Este proceso no siempre borrará la caché (dependerá del navegador) pero reiniciará la petición de configuración de la página.
                    </small>
                </p>
                <div className="actions">
                    <Button className="app-button" type="primary" onClick={hideRefreshCache}>No, cerrar esta ventana.</Button>
                    <Button className="app-button danger" type="primary" danger onClick={goRefreshUrl}>Sí, recargar.</Button>
                </div>
            </div>
        </div>
    )
}


export default RefreshCacheComponent;